var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (loading, todos) {pug_html = pug_html + "\u003Cdiv class=\"todos-list\"\u003E";
if (loading) {
pug_html = pug_html + "\u003Cdiv class=\"content text-left\"\u003E\u003Cdiv class=\"ui active centered inline small grey loader\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
if (todos) {
// iterate todos
;(function(){
  var $$obj = todos;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var todo = $$obj[pug_index0];
if ((todo.status == 'incomplete') || (todo.status == 'upcoming')) todo.icon = 'grey circle outline'
else if (todo.status == 'complete') todo.icon = 'green check circle'
else if (todo.status == 'overdue') todo.icon = 'orange circle outline'
else if (todo.status == 'expired') todo.icon = 'red clock'
pug_html = pug_html + "\u003Cdiv" + (" class=\"todo-item\""+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Cspan" + (pug.attr("class", pug.classes([`todos-status-${todo.status}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "&nbsp;";
if (todo.assignedPeople) {
// iterate todo.assignedPeople
;(function(){
  var $$obj = todo.assignedPeople;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var person = $$obj[pug_index1];
pug_html = pug_html + "&nbsp;\u003Cdiv class=\"ui tiny label\"\u003E" + (pug.escape(null == (pug_interp = `${person.firstname} ${person.lastname}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var person = $$obj[pug_index1];
pug_html = pug_html + "&nbsp;\u003Cdiv class=\"ui tiny label\"\u003E" + (pug.escape(null == (pug_interp = `${person.firstname} ${person.lastname}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

}
if (todo.dueAtDisplay) {
pug_html = pug_html + "&nbsp;\u003Cdiv" + (pug.attr("class", pug.classes(["ui","label","tiny","dueAtjs",`${todo.status === 'overdue' ? 'orange' : todo.status === 'expired' ? 'red' : 'basic' }`], [false,false,false,false,true]), false, true)) + "\u003E\u003Ci class=\"calendar icon\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = todo.dueAtDisplay) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var todo = $$obj[pug_index0];
if ((todo.status == 'incomplete') || (todo.status == 'upcoming')) todo.icon = 'grey circle outline'
else if (todo.status == 'complete') todo.icon = 'green check circle'
else if (todo.status == 'overdue') todo.icon = 'orange circle outline'
else if (todo.status == 'expired') todo.icon = 'red clock'
pug_html = pug_html + "\u003Cdiv" + (" class=\"todo-item\""+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Cspan" + (pug.attr("class", pug.classes([`todos-status-${todo.status}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "&nbsp;";
if (todo.assignedPeople) {
// iterate todo.assignedPeople
;(function(){
  var $$obj = todo.assignedPeople;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var person = $$obj[pug_index2];
pug_html = pug_html + "&nbsp;\u003Cdiv class=\"ui tiny label\"\u003E" + (pug.escape(null == (pug_interp = `${person.firstname} ${person.lastname}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var person = $$obj[pug_index2];
pug_html = pug_html + "&nbsp;\u003Cdiv class=\"ui tiny label\"\u003E" + (pug.escape(null == (pug_interp = `${person.firstname} ${person.lastname}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

}
if (todo.dueAtDisplay) {
pug_html = pug_html + "&nbsp;\u003Cdiv" + (pug.attr("class", pug.classes(["ui","label","tiny","dueAtjs",`${todo.status === 'overdue' ? 'orange' : todo.status === 'expired' ? 'red' : 'basic' }`], [false,false,false,false,true]), false, true)) + "\u003E\u003Ci class=\"calendar icon\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = todo.dueAtDisplay) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

}
else {
pug_html = pug_html + "\u003Cdiv class=\"content text-left\"\u003E\u003Cspan class=\"yellow-strip\"\u003E\u003Cem\u003EThere are no to-dos yet...\u003C\u002Fem\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"loading" in locals_for_with?locals_for_with.loading:typeof loading!=="undefined"?loading:undefined,"todos" in locals_for_with?locals_for_with.todos:typeof todos!=="undefined"?todos:undefined));;return pug_html;};
module.exports = template;