var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (baseGroupId, groups) {pug_html = pug_html + "\u003Cdiv class=\"ui breadcrumb\" id=\"breadcrumb\"\u003E\u003Cdiv class=\"section\"\u003EPeople, organizations &amp; teams\u003C\u002Fdiv\u003E\u003Cdiv class=\"divider\"\u003E\u002F\u003C\u002Fdiv\u003E";
var count = groups.length - 1
// iterate groups
;(function(){
  var $$obj = groups;
  if ('number' == typeof $$obj.length) {
      for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
        var group = $$obj[i];
if ((i < count)) {
pug_html = pug_html + "\u003Ca" + (" class=\"section\""+pug.attr("href", `${baseGroupId}/people/${group.id}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = group.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Cdiv class=\"divider\"\u003E\u002F\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = group.label) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;
      var group = $$obj[i];
if ((i < count)) {
pug_html = pug_html + "\u003Ca" + (" class=\"section\""+pug.attr("href", `${baseGroupId}/people/${group.id}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = group.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Cdiv class=\"divider\"\u003E\u002F\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = group.label) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"baseGroupId" in locals_for_with?locals_for_with.baseGroupId:typeof baseGroupId!=="undefined"?baseGroupId:undefined,"groups" in locals_for_with?locals_for_with.groups:typeof groups!=="undefined"?groups:undefined));;return pug_html;};
module.exports = template;