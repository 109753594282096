var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (assessmentRules, config, progressBars) {pug_html = pug_html + "\u003Cdiv" + (pug.attr("id", `${config.selector}`, true, true)) + "\u003E\u003Cdiv class=\"ui grid\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"seven wide column center aligned\"\u003E\u003Ch3\u003E" + (pug.escape(null == (pug_interp = config.options.title) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E";
// iterate progressBars
;(function(){
  var $$obj = progressBars;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var bar = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["ui","indicating","progress",bar.size], [false,false,false,true]), false, true)+pug.attr("data-percent", bar.percentage, true, true)) + "\u003E\u003Cdiv class=\"bar\"\u003E\u003Cdiv class=\"progress\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"label\"\u003E" + (pug.escape(null == (pug_interp = bar.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var bar = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["ui","indicating","progress",bar.size], [false,false,false,true]), false, true)+pug.attr("data-percent", bar.percentage, true, true)) + "\u003E\u003Cdiv class=\"bar\"\u003E\u003Cdiv class=\"progress\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"label\"\u003E" + (pug.escape(null == (pug_interp = bar.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"one wide column\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"seven wide column left aligned\"\u003E";
if (assessmentRules) {
if (assessmentRules.helpguide) {
pug_html = pug_html + ("\u003Ch3\u003EStep-by-step guides to help you do the Assessment\u003C\u002Fh3\u003E" + (null == (pug_interp = assessmentRules.helpguide) ? "" : pug_interp));
}
if (assessmentRules.requirement) {
pug_html = pug_html + ("\u003Ch3\u003ERequirements to complete this Assessment: \u003C\u002Fh3\u003E" + (null == (pug_interp = assessmentRules.requirement) ? "" : pug_interp));
}
if (assessmentRules.nextStep) {
pug_html = pug_html + ("\u003Ch3\u003EWhat happens next?\u003C\u002Fh3\u003E" + (null == (pug_interp = assessmentRules.nextStep) ? "" : pug_interp));
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"one wide column\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"assessmentRules" in locals_for_with?locals_for_with.assessmentRules:typeof assessmentRules!=="undefined"?assessmentRules:undefined,"config" in locals_for_with?locals_for_with.config:typeof config!=="undefined"?config:undefined,"progressBars" in locals_for_with?locals_for_with.progressBars:typeof progressBars!=="undefined"?progressBars:undefined));;return pug_html;};
module.exports = template;