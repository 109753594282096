var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (JSON, contentMaxHeight, groupId, isLoading, list, listGroupId, listing, parentListGroupId, segment) {pug_html = pug_html + "\u003Cdiv" + (" class=\"panel\""+" id=\"listjs\""+pug.attr("style", pug.style(`height: ${contentMaxHeight}px;`), true, true)) + "\u003E";
if (isLoading) {
pug_html = pug_html + "\u003Cbr\u003E\u003Cdiv class=\"ui active centered inline small grey loader\"\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"ui middle aligned divided selection list\"\u003E";
if (listing.length == 0) {
pug_html = pug_html + "\u003Cdiv class=\"item\"\u003E\u003Cdiv class=\"content text-center\"\u003E\u003Cspan class=\"yellow-strip\"\u003ENo items are available...\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
// iterate listing
;(function(){
  var $$obj = listing;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var item = $$obj[pug_index0];
var popupHtml = ''
var url = `/hub/${listGroupId}/profiles/${list}/${segment}/${item.kind}/${item.id}`
if ((item.tags.length > 1)) {
// iterate item.tags
;(function(){
  var $$obj = item.tags;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var tag = $$obj[pug_index1];
popupHtml += `<div class="ui tiny label">${tag}</div>`
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var tag = $$obj[pug_index1];
popupHtml += `<div class="ui tiny label">${tag}</div>`
    }
  }
}).call(this);

}
if ((item.kind == 'person')) {
url = `/hub/${groupId}_${listGroupId}_${parentListGroupId}/profiles/${list}/${segment}/${item.kind}/${item.id}`
pug_html = pug_html + "\u003Ca" + (" class=\"item showprofilepopoverjs\""+pug.attr("href", url, true, true)+pug.attr("id", `list-${item.kind}-${item.id}`, true, true)+pug.attr("data-html", popupHtml, true, true)+" data-position=\"right center\""+pug.attr("data-profile", JSON.stringify(item), true, true)) + "\u003E\u003Cdiv class=\"right floated bottom aligned content\"\u003E";
if ((item.tags.length > 0)) {
pug_html = pug_html + "\u003Cdiv class=\"ui tiny label\"\u003E" + (pug.escape(null == (pug_interp = item.tags[0]) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
if ((item.tags.length > 1)) {
pug_html = pug_html + "\u003Cdiv class=\"ui tiny label\"\u003E+" + (pug.escape(null == (pug_interp = item.tags.length-1) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cimg" + (" class=\"ui avatar image\""+pug.attr("src", item.avatar, true, true)) + "\u003E\u003Cdiv class=\"content\"\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E";
}
else
if ((item.kind == 'group')) {
url = `/hub/${groupId}_${listGroupId}_${parentListGroupId}/profiles/${list}/${segment}/${item.kind}/${item.id}`
pug_html = pug_html + "\u003Cdiv" + (" class=\"item\""+pug.attr("href", url, true, true)+pug.attr("id", `list-${item.kind}-${item.id}`, true, true)+pug.attr("data-html", popupHtml, true, true)+" data-position=\"right center\""+pug.attr("data-profile", JSON.stringify(item), true, true)) + "\u003E\u003Cdiv class=\"right floated bottom aligned content\"\u003E\u003Ca" + (" class=\"ui mini button green inverted\""+pug.attr("href", url, true, true)) + "\u003EProfile &rarr;\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cimg" + (" class=\"ui avatar image\""+pug.attr("src", item.avatar, true, true)) + "\u003E\u003Cdiv class=\"content\"\u003E\u003Ca" + (pug.attr("href", `/hub/${groupId}_${item.id}_${listGroupId}/profiles/${list}/${segment}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else
if ((item.kind == 'contact')) {
url = `/hub/${groupId}_${listGroupId}_${parentListGroupId}/profiles/${list}/${segment}/${item.kind}/${item.id}`
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", url, true, true)+pug.attr("id", `list-${item.kind}-${item.id}`, true, true)+pug.attr("data-profile", JSON.stringify(item), true, true)) + "\u003E\u003Cdiv class=\"right floated bottom aligned content\"\u003E\u003C\u002Fdiv\u003E\u003Cimg" + (" class=\"ui avatar image\""+pug.attr("src", item.avatar, true, true)) + "\u003E\u003Cdiv class=\"content\"\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E";
}
else {
url = `/hub/${groupId}_${listGroupId}_${parentListGroupId}/profiles/${list}/${segment}/${item.kind}/${item.id}`
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", url, true, true)+pug.attr("id", `list-${item.kind}-${item.id}`, true, true)+pug.attr("data-profile", JSON.stringify(item), true, true)) + "\u003E\u003Cdiv class=\"right floated bottom aligned content\"\u003E\u003C\u002Fdiv\u003E\u003Ci" + (pug.attr("class", pug.classes(["icon","large",item.avatarIcon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"content\"\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var item = $$obj[pug_index0];
var popupHtml = ''
var url = `/hub/${listGroupId}/profiles/${list}/${segment}/${item.kind}/${item.id}`
if ((item.tags.length > 1)) {
// iterate item.tags
;(function(){
  var $$obj = item.tags;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var tag = $$obj[pug_index2];
popupHtml += `<div class="ui tiny label">${tag}</div>`
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var tag = $$obj[pug_index2];
popupHtml += `<div class="ui tiny label">${tag}</div>`
    }
  }
}).call(this);

}
if ((item.kind == 'person')) {
url = `/hub/${groupId}_${listGroupId}_${parentListGroupId}/profiles/${list}/${segment}/${item.kind}/${item.id}`
pug_html = pug_html + "\u003Ca" + (" class=\"item showprofilepopoverjs\""+pug.attr("href", url, true, true)+pug.attr("id", `list-${item.kind}-${item.id}`, true, true)+pug.attr("data-html", popupHtml, true, true)+" data-position=\"right center\""+pug.attr("data-profile", JSON.stringify(item), true, true)) + "\u003E\u003Cdiv class=\"right floated bottom aligned content\"\u003E";
if ((item.tags.length > 0)) {
pug_html = pug_html + "\u003Cdiv class=\"ui tiny label\"\u003E" + (pug.escape(null == (pug_interp = item.tags[0]) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
if ((item.tags.length > 1)) {
pug_html = pug_html + "\u003Cdiv class=\"ui tiny label\"\u003E+" + (pug.escape(null == (pug_interp = item.tags.length-1) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cimg" + (" class=\"ui avatar image\""+pug.attr("src", item.avatar, true, true)) + "\u003E\u003Cdiv class=\"content\"\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E";
}
else
if ((item.kind == 'group')) {
url = `/hub/${groupId}_${listGroupId}_${parentListGroupId}/profiles/${list}/${segment}/${item.kind}/${item.id}`
pug_html = pug_html + "\u003Cdiv" + (" class=\"item\""+pug.attr("href", url, true, true)+pug.attr("id", `list-${item.kind}-${item.id}`, true, true)+pug.attr("data-html", popupHtml, true, true)+" data-position=\"right center\""+pug.attr("data-profile", JSON.stringify(item), true, true)) + "\u003E\u003Cdiv class=\"right floated bottom aligned content\"\u003E\u003Ca" + (" class=\"ui mini button green inverted\""+pug.attr("href", url, true, true)) + "\u003EProfile &rarr;\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cimg" + (" class=\"ui avatar image\""+pug.attr("src", item.avatar, true, true)) + "\u003E\u003Cdiv class=\"content\"\u003E\u003Ca" + (pug.attr("href", `/hub/${groupId}_${item.id}_${listGroupId}/profiles/${list}/${segment}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else
if ((item.kind == 'contact')) {
url = `/hub/${groupId}_${listGroupId}_${parentListGroupId}/profiles/${list}/${segment}/${item.kind}/${item.id}`
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", url, true, true)+pug.attr("id", `list-${item.kind}-${item.id}`, true, true)+pug.attr("data-profile", JSON.stringify(item), true, true)) + "\u003E\u003Cdiv class=\"right floated bottom aligned content\"\u003E\u003C\u002Fdiv\u003E\u003Cimg" + (" class=\"ui avatar image\""+pug.attr("src", item.avatar, true, true)) + "\u003E\u003Cdiv class=\"content\"\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E";
}
else {
url = `/hub/${groupId}_${listGroupId}_${parentListGroupId}/profiles/${list}/${segment}/${item.kind}/${item.id}`
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", url, true, true)+pug.attr("id", `list-${item.kind}-${item.id}`, true, true)+pug.attr("data-profile", JSON.stringify(item), true, true)) + "\u003E\u003Cdiv class=\"right floated bottom aligned content\"\u003E\u003C\u002Fdiv\u003E\u003Ci" + (pug.attr("class", pug.classes(["icon","large",item.avatarIcon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"content\"\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E";
}
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"JSON" in locals_for_with?locals_for_with.JSON:typeof JSON!=="undefined"?JSON:undefined,"contentMaxHeight" in locals_for_with?locals_for_with.contentMaxHeight:typeof contentMaxHeight!=="undefined"?contentMaxHeight:undefined,"groupId" in locals_for_with?locals_for_with.groupId:typeof groupId!=="undefined"?groupId:undefined,"isLoading" in locals_for_with?locals_for_with.isLoading:typeof isLoading!=="undefined"?isLoading:undefined,"list" in locals_for_with?locals_for_with.list:typeof list!=="undefined"?list:undefined,"listGroupId" in locals_for_with?locals_for_with.listGroupId:typeof listGroupId!=="undefined"?listGroupId:undefined,"listing" in locals_for_with?locals_for_with.listing:typeof listing!=="undefined"?listing:undefined,"parentListGroupId" in locals_for_with?locals_for_with.parentListGroupId:typeof parentListGroupId!=="undefined"?parentListGroupId:undefined,"segment" in locals_for_with?locals_for_with.segment:typeof segment!=="undefined"?segment:undefined));;return pug_html;};
module.exports = template;