var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (_, activities, component, groupId, personId, taskflowId) {var addAction = _.find(component.componentActions, ( (params, action)=> { return action == 'add' }))
var continueAction = _.find(component.componentActions, ( (params, action)=> { return action == 'continue' }))
var deleteAction = _.find(component.componentActions, ( (params, action)=> { return action == 'delete' }))
var addUrl = (addAction && (addAction.target) && (addAction.target == 'blank-taskflow')) ? `/hub/group/${groupId}/taskflow/new/${taskflowId}/full` : `/hub/taskflow/start/${groupId}/${taskflowId}/modal`
pug_html = pug_html + "\u003Cdiv class=\"content\"\u003E\u003Cdiv class=\"pull-left\"\u003E\u003Ch3\u003E" + (pug.escape(null == (pug_interp = component.label) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"pull-right\"\u003E";
if ((component.layout == 'table')) {
if (deleteAction) {
pug_html = pug_html + "\u003Cbutton class=\"ui tiny red inverted button btn-delete-js\"\u003E\u003Ci class=\"trash alternate outline icon\"\u003E\u003C\u002Fi\u003EDelete\u003C\u002Fbutton\u003E";
}
if (addAction) {
var addActionTarget = (addAction.target) ? addAction.target : 'modal-taskflow'
var addActionUrl = (addAction.params) ? `${addUrl}?${addAction.params}`.replace(/\${personId}/mg, personId).replace(/\${groupId}/mg, groupId) : `${addUrl}`
var addActionAutoclose = (addAction.autoclose) ? 'true' : ''
pug_html = pug_html + "\u003Cbutton" + (" class=\"ui tiny green button\""+pug.attr("data-target", addActionTarget, true, true)+pug.attr("data-url", addActionUrl, true, true)+pug.attr("data-autoclose", addActionAutoclose, true, true)) + "\u003E\u003Ci class=\"plus icon\"\u003E\u003C\u002Fi\u003E";
if (addAction.title) {
pug_html = pug_html + (pug.escape(null == (pug_interp = addAction.title) ? "" : pug_interp));
}
else {
pug_html = pug_html + "New";
}
pug_html = pug_html + "\u003C\u002Fbutton\u003E";
}
}
else
if ((component.layout == 'card')) {
if ((activities.length <= 0)) {
if (addAction) {
var addActionTarget = (addAction.target) ? addAction.target : 'modal-taskflow'
var addActionUrl = (addAction.params) ? `${addUrl}?${addAction.params}`.replace(/\${personId}/mg, personId).replace(/\${groupId}/mg, groupId) : `${addUrl}`
var addActionAutoclose = (addAction.autoclose) ? 'true' : ''
pug_html = pug_html + "\u003Cbutton" + (" class=\"ui mini green button\""+pug.attr("data-target", addActionTarget, true, true)+pug.attr("data-url", addActionUrl, true, true)+pug.attr("data-autoclose", addActionAutoclose, true, true)) + "\u003E\u003Ci class=\"plus icon\"\u003E\u003C\u002Fi\u003E";
if (addAction.title) {
pug_html = pug_html + (pug.escape(null == (pug_interp = addAction.title) ? "" : pug_interp));
}
else {
pug_html = pug_html + "Add";
}
pug_html = pug_html + "\u003C\u002Fbutton\u003E";
}
}
else {
if (continueAction) {
var continueActionAutoclose = (continueAction.autoclose) ? 'true' : ''
pug_html = pug_html + "\u003Ci" + (" class=\"link blue write icon\""+pug.attr("data-url", `/hub/taskflow/continue/${groupId}/${activities[0].processId}/hub?${continueAction.params}`, true, true)+" data-target=\"modal-taskflow\""+pug.attr("data-autoclose", continueActionAutoclose, true, true)) + "\u003E\u003C\u002Fi\u003E";
}
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"_" in locals_for_with?locals_for_with._:typeof _!=="undefined"?_:undefined,"activities" in locals_for_with?locals_for_with.activities:typeof activities!=="undefined"?activities:undefined,"component" in locals_for_with?locals_for_with.component:typeof component!=="undefined"?component:undefined,"groupId" in locals_for_with?locals_for_with.groupId:typeof groupId!=="undefined"?groupId:undefined,"personId" in locals_for_with?locals_for_with.personId:typeof personId!=="undefined"?personId:undefined,"taskflowId" in locals_for_with?locals_for_with.taskflowId:typeof taskflowId!=="undefined"?taskflowId:undefined));;return pug_html;};
module.exports = template;