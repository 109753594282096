var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (menu) {pug_html = pug_html + "\u003Cdiv id=\"workarea\"\u003E\u003Cdiv class=\"ui centered grid\"\u003E";
if ((menu.length == 0)) {
pug_html = pug_html + "\u003Cdiv class=\"six wide column\"\u003E\u003Cdiv class=\"ui segment center aligned\"\u003E\u003Cspan class=\"yellow-strip\"\u003ENo 'Live Workflows' are available...\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
// iterate menu
;(function(){
  var $$obj = menu;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var item = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"five wide column\"\u003E";
if (item.sub) {
pug_html = pug_html + "\u003Cdiv class=\"ui segments\"\u003E\u003Cdiv class=\"ui segment\"\u003E\u003Ch3\u003E" + (pug.escape(null == (pug_interp = item.title) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"ui segment ellipsis\"\u003E\u003Ctable class=\"ui very basic compact single line table\"\u003E\u003Ctbody\u003E";
// iterate item.sub
;(function(){
  var $$obj = item.sub;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var itemSub = $$obj[pug_index1];
if (itemSub.sub) {
}
else {
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E\u003Ca" + (pug.attr("href", `${itemSub.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var itemSub = $$obj[pug_index1];
if (itemSub.sub) {
}
else {
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E\u003Ca" + (pug.attr("href", `${itemSub.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else
if (item.url) {
pug_html = pug_html + "\u003Cdiv class=\"ui segments\"\u003E\u003Cdiv class=\"ui segment\"\u003E\u003Ch3\u003E" + (pug.escape(null == (pug_interp = item.title) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"ui segment ellipsis\"\u003E\u003Ctable class=\"ui very basic compact single line table\"\u003E\u003Ctbody\u003E\u003Ctr\u003E\u003Ctd\u003E\u003Ca" + (pug.attr("href", `${item.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var item = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"five wide column\"\u003E";
if (item.sub) {
pug_html = pug_html + "\u003Cdiv class=\"ui segments\"\u003E\u003Cdiv class=\"ui segment\"\u003E\u003Ch3\u003E" + (pug.escape(null == (pug_interp = item.title) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"ui segment ellipsis\"\u003E\u003Ctable class=\"ui very basic compact single line table\"\u003E\u003Ctbody\u003E";
// iterate item.sub
;(function(){
  var $$obj = item.sub;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var itemSub = $$obj[pug_index2];
if (itemSub.sub) {
}
else {
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E\u003Ca" + (pug.attr("href", `${itemSub.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var itemSub = $$obj[pug_index2];
if (itemSub.sub) {
}
else {
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E\u003Ca" + (pug.attr("href", `${itemSub.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else
if (item.url) {
pug_html = pug_html + "\u003Cdiv class=\"ui segments\"\u003E\u003Cdiv class=\"ui segment\"\u003E\u003Ch3\u003E" + (pug.escape(null == (pug_interp = item.title) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"ui segment ellipsis\"\u003E\u003Ctable class=\"ui very basic compact single line table\"\u003E\u003Ctbody\u003E\u003Ctr\u003E\u003Ctd\u003E\u003Ca" + (pug.attr("href", `${item.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"menu" in locals_for_with?locals_for_with.menu:typeof menu!=="undefined"?menu:undefined));;return pug_html;};
module.exports = template;