var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (roles) {pug_html = pug_html + "\u003Cdiv class=\"text-center\" id=\"person-badge-roles\"\u003E";
// iterate roles
;(function(){
  var $$obj = roles;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var role = $$obj[pug_index0];
if ((role.label)) {
pug_html = pug_html + "\u003Cdiv class=\"ui small label\"\u003E" + (pug.escape(null == (pug_interp = role.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var role = $$obj[pug_index0];
if ((role.label)) {
pug_html = pug_html + "\u003Cdiv class=\"ui small label\"\u003E" + (pug.escape(null == (pug_interp = role.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"roles" in locals_for_with?locals_for_with.roles:typeof roles!=="undefined"?roles:undefined));;return pug_html;};
module.exports = template;