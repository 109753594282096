var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (id, label, profile) {pug_html = pug_html + "\u003Cdiv" + (" class=\"sixteen wide column\""+pug.attr("id", `${id}`, true, true)) + "\u003E\u003Cdiv class=\"ui fluid raised card\"\u003E\u003Cdiv class=\"content\"\u003E\u003Cdiv class=\"pull-left\"\u003E\u003Ch3\u003E\u003Cimg" + (" class=\"ui circular image\""+pug.attr("src", profile.avatar, true, true)) + "\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"pull-right\"\u003E\u003Ca class=\"contact-update-js\" data-target=\"modal-contact-update\"\u003E\u003Ci class=\"write icon\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"content\"\u003E\u003Ctable class=\"ui very basic compact unstackable table keyval\"\u003E\u003Ctbody\u003E\u003Ctr\u003E\u003Ctd\u003EFullname\u003C\u002Ftd\u003E\u003Ctd\u003E\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = profile.label) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
if (profile.full.email) {
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E\u003Ci class=\"icon envelope\"\u003E\u003C\u002Fi\u003EEmail\u003C\u002Ftd\u003E\u003Ctd\u003E\u003Ca" + (pug.attr("href", `mailto:${profile.full.email}`, true, true)) + "\u003E\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = profile.full.email) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E\u003C\u002Fa\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
}
if (profile.full.mobile) {
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E\u003Ci class=\"icon mobile alternate\"\u003E\u003C\u002Fi\u003EMobile\u003C\u002Ftd\u003E\u003Ctd\u003E\u003Ca" + (pug.attr("href", `tel:${profile.full.mobile}`, true, true)) + "\u003E\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = profile.full.mobile) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E\u003C\u002Fa\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
}
if (profile.full.uniqueId) {
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E\u003Ci class=\"icon address card\"\u003E\u003C\u002Fi\u003EStudent number\u003C\u002Ftd\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = profile.full.uniqueId) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
}
if (profile.full.properties) {
// iterate profile.full.properties
;(function(){
  var $$obj = profile.full.properties;
  if ('number' == typeof $$obj.length) {
      for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
        var val = $$obj[key];
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = `${key}`) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = val) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;
      var val = $$obj[key];
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = `${key}`) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = val) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"id" in locals_for_with?locals_for_with.id:typeof id!=="undefined"?id:undefined,"label" in locals_for_with?locals_for_with.label:typeof label!=="undefined"?label:undefined,"profile" in locals_for_with?locals_for_with.profile:typeof profile!=="undefined"?profile:undefined));;return pug_html;};
module.exports = template;