var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (component, label, layout, taskflowId) {if (!(component)) {
if ((layout == 'table') || (layout == 'fullcard')) {
pug_html = pug_html + "\u003Cdiv" + (" class=\"sixteen wide column\""+pug.attr("id", `profile-${taskflowId}`, true, true)) + "\u003E\u003Cdiv class=\"ui fluid raised card\"\u003E\u003Cdiv class=\"content text-center\"\u003E\u003Cdiv class=\"ui active centered inline small grey loader\"\u003E\u003C\u002Fdiv\u003E\u003Csmall\u003E\u003Ccenter\u003E" + (pug.escape(null == (pug_interp = `Fetching ${label}...`) ? "" : pug_interp)) + "\u003C\u002Fcenter\u003E\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else
if ((layout == 'card') || (layout == 'halftable')) {
pug_html = pug_html + "\u003Cdiv" + (" class=\"eight wide column\""+pug.attr("id", `profile-${taskflowId}`, true, true)) + "\u003E\u003Cdiv class=\"ui fluid raised card\"\u003E\u003Cdiv class=\"content text-center\"\u003E\u003Cdiv class=\"ui active centered inline small grey loader\"\u003E\u003C\u002Fdiv\u003E\u003Csmall\u003E\u003Ccenter\u003E" + (pug.escape(null == (pug_interp = `Fetching ${label}...`) ? "" : pug_interp)) + "\u003C\u002Fcenter\u003E\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
}
else {
if (component.layout == 'table') {
pug_html = pug_html + "\u003Cdiv" + (" class=\"sixteen wide column\""+pug.attr("id", `profile-${taskflowId}`, true, true)) + "\u003E\u003Cdiv class=\"ui fluid raised card\"\u003E" + (null == (pug_interp = require("./profile-component-header.pug").call(this, locals)) ? "" : pug_interp) + (null == (pug_interp = require("./profile-component-table.pug").call(this, locals)) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else
if (component.layout == 'halftable') {
pug_html = pug_html + "\u003Cdiv" + (" class=\"eight wide column\""+pug.attr("id", `profile-${taskflowId}`, true, true)) + "\u003E\u003Cdiv class=\"ui fluid raised card\"\u003E" + (null == (pug_interp = require("./profile-component-header.pug").call(this, locals)) ? "" : pug_interp) + (null == (pug_interp = require("./profile-component-halftable.pug").call(this, locals)) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else
if (component.layout == 'card') {
pug_html = pug_html + "\u003Cdiv" + (" class=\"eight wide column\""+pug.attr("id", `profile-${taskflowId}`, true, true)) + "\u003E\u003Cdiv class=\"ui fluid raised card\"\u003E" + (null == (pug_interp = require("./profile-component-header.pug").call(this, locals)) ? "" : pug_interp) + (null == (pug_interp = require("./profile-component-card.pug").call(this, locals)) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
if (component.layout == 'fullcard') {
pug_html = pug_html + "\u003Cdiv" + (" class=\"sixteen wide column\""+pug.attr("id", `profile-${taskflowId}`, true, true)) + "\u003E\u003Cdiv class=\"ui fluid raised card\"\u003E" + (null == (pug_interp = require("./profile-component-header.pug").call(this, locals)) ? "" : pug_interp) + (null == (pug_interp = require("./profile-component-card.pug").call(this, locals)) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
}}.call(this,"component" in locals_for_with?locals_for_with.component:typeof component!=="undefined"?component:undefined,"label" in locals_for_with?locals_for_with.label:typeof label!=="undefined"?label:undefined,"layout" in locals_for_with?locals_for_with.layout:typeof layout!=="undefined"?layout:undefined,"taskflowId" in locals_for_with?locals_for_with.taskflowId:typeof taskflowId!=="undefined"?taskflowId:undefined));;return pug_html;};
module.exports = template;