var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (announcements) {pug_html = pug_html + "\u003Cdiv class=\"kotive-task-body\"\u003E";
if ((announcements.length == 0)) {
pug_html = pug_html + "\u003Cspan class=\"yellow-strip\"\u003EThere are now announcements...\u003C\u002Fspan\u003E";
}
else {
// iterate announcements
;(function(){
  var $$obj = announcements;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var item = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"pull-right\"\u003E\u003Cspan class=\"yellow-strip\"\u003E\u003Cem\u003E" + (pug.escape(null == (pug_interp = item.displayAt) ? "" : pug_interp)) + "\u003C\u002Fem\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E" + (null == (pug_interp = item.message) ? "" : pug_interp) + "\u003Cp\u003E\u003C\u002Fp\u003E\u003Chr\u003E\u003Cp\u003E\u003C\u002Fp\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var item = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"pull-right\"\u003E\u003Cspan class=\"yellow-strip\"\u003E\u003Cem\u003E" + (pug.escape(null == (pug_interp = item.displayAt) ? "" : pug_interp)) + "\u003C\u002Fem\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E" + (null == (pug_interp = item.message) ? "" : pug_interp) + "\u003Cp\u003E\u003C\u002Fp\u003E\u003Chr\u003E\u003Cp\u003E\u003C\u002Fp\u003E";
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"announcements" in locals_for_with?locals_for_with.announcements:typeof announcements!=="undefined"?announcements:undefined));;return pug_html;};
module.exports = template;