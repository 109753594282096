var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (isMe) {pug_html = pug_html + "\u003Cdiv id=\"workarea\"\u003E\u003Cdiv class=\"ui grid stackable\"\u003E\u003Cdiv class=\"sixteen wide column\"\u003E\u003Cdiv class=\"ui breadcrumb\" id=\"breadcrumb\"\u003E";
if (isMe) {
pug_html = pug_html + "\u003Cdiv class=\"section\"\u003EMy profile\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"section\"\u003EPeople, organizations &amp; teams\u003C\u002Fdiv\u003E\u003Cdiv class=\"divider\"\u003E\u002F\u003C\u002Fdiv\u003E\u003Cdiv class=\"section\"\u003E\u003Cstrong\u003E...\u003C\u002Fstrong\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"pull-right\" id=\"person-actions\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"four wide column\"\u003E\u003Cdiv class=\"ui raised fluid card\" id=\"person-badge\"\u003E\u003Cdiv class=\"content text-center\"\u003E\u003Cimg" + (" class=\"ui centered image\""+pug.attr("src", require('../../images/loading.gif'), true, true)+" alt=\"\"") + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"ui vertical text tiny fluid menu\" id=\"person-quicklinks\" style=\"display: none; margin-left: 15px;\"\u003E\u003Cdiv class=\"item header\"\u003EQuicklinks\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"feed-timeline\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"todos-card\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"twelve wide column\"\u003E\u003Cdiv class=\"ui grid stackable\" id=\"person-sheet\"\u003E\u003Cimg" + (" class=\"ui centered image\""+pug.attr("src", require('../../images/loading.gif'), true, true)+" alt=\"\"") + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"isMe" in locals_for_with?locals_for_with.isMe:typeof isMe!=="undefined"?isMe:undefined));;return pug_html;};
module.exports = template;