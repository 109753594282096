var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (JSON, groupId, loadingHistory, records) {pug_html = pug_html + "\u003Cdiv class=\"timeline-container\" id=\"timeline\"\u003E\u003Cdiv class=\"timeline-start\"\u003E\u003C\u002Fdiv\u003E";
if (loadingHistory) {
pug_html = pug_html + "\u003Cdiv class=\"timeline-card\"\u003E\u003Cdiv class=\"timeline-img\" style=\"padding-top: 17px;\"\u003E\u003Cdiv class=\"ui active centered inline small grey loader\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
// iterate records
;(function(){
  var $$obj = records;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var record = $$obj[pug_index0];
pug_html = pug_html + ("\u003Cdiv class=\"timeline-card\"\u003E\u003Cdiv class=\"timeline-img\"\u003E\u003Cimg" + (pug.attr("src", record.icon, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"timeline-content\"\u003E\u003Cdiv" + (" class=\"ui fluid raised card\""+pug.attr("id", `${record.activityData.templateActivityId}-${record.activityData.id}`, true, true)+pug.attr("data-clone", `${JSON.stringify(record.cloneTaskData)}`, true, true)) + "\u003E\u003Cdiv class=\"content\"\u003E\u003Cdiv class=\"header\"\u003E" + (pug.escape(null == (pug_interp = record.activityData.label) ? "" : pug_interp)));
if (record.activityData.serviceResponses) {
if (!(record.responseSuccess)) {
pug_html = pug_html + "\u003Cspan class=\"right floated\"\u003E\u003Ca" + (" class=\"mini red ui button retry-taskflow-js\""+pug.attr("href", `/api/group/${groupId}/process/${record.activityData.processId}/retry`, true, true)+" title=\"Retry the failed task within this taskflow\"") + "\u003ERetry?\u003C\u002Fa\u003E\u003C\u002Fspan\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"content\"\u003E\u003Ctable class=\"ui very basic celled compact unstackable table\"\u003E\u003Ctbody\u003E";
// iterate record.activityData.fields
;(function(){
  var $$obj = record.activityData.fields;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var field = $$obj[pug_index1];
pug_html = pug_html + ("\u003Ctr" + (pug.attr("id", `${field.templateFieldId}-${field.id}`, true, true)) + "\u003E\u003Ctd class=\"six wide\"\u003E" + (pug.escape(null == (pug_interp = field.label) ? "" : pug_interp)));
if (!(field.required)) {
pug_html = pug_html + "&nbsp;\u003Cspan class=\"optional\"\u003E&nbsp;optional&nbsp;\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd class=\"ten wide\"\u003E";
if ((field.style.match(/\"kind\":\"upload\"/mg) || field.style.match(/\"kind\":\"uploadshow\"/mg))) {
if (field.value != "") {
var uploads = JSON.parse(field.value)
// iterate uploads.reverse()
;(function(){
  var $$obj = uploads.reverse();
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var upload = $$obj[pug_index2];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", `https://s3.amazonaws.com/${upload.container}/${upload.key.replace(/\+/g, '%2B').replace(/%20/g, '+')}`, true, true)+" target=\"_blank\"") + "\u003E" + (pug.escape(null == (pug_interp = upload.filename) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Cbr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var upload = $$obj[pug_index2];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", `https://s3.amazonaws.com/${upload.container}/${upload.key.replace(/\+/g, '%2B').replace(/%20/g, '+')}`, true, true)+" target=\"_blank\"") + "\u003E" + (pug.escape(null == (pug_interp = upload.filename) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Cbr\u003E";
    }
  }
}).call(this);

}
}
else
if (field.style.match(/\"kind\":\"url\"/mg)) {
if (field.value != "") {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", `${field.value}`, true, true)+" target=\"_blank\"") + "\u003E" + (pug.escape(null == (pug_interp = field.value) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
}
else {
pug_html = pug_html + (null == (pug_interp = field.value) ? "" : pug_interp);
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var field = $$obj[pug_index1];
pug_html = pug_html + ("\u003Ctr" + (pug.attr("id", `${field.templateFieldId}-${field.id}`, true, true)) + "\u003E\u003Ctd class=\"six wide\"\u003E" + (pug.escape(null == (pug_interp = field.label) ? "" : pug_interp)));
if (!(field.required)) {
pug_html = pug_html + "&nbsp;\u003Cspan class=\"optional\"\u003E&nbsp;optional&nbsp;\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd class=\"ten wide\"\u003E";
if ((field.style.match(/\"kind\":\"upload\"/mg) || field.style.match(/\"kind\":\"uploadshow\"/mg))) {
if (field.value != "") {
var uploads = JSON.parse(field.value)
// iterate uploads.reverse()
;(function(){
  var $$obj = uploads.reverse();
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var upload = $$obj[pug_index3];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", `https://s3.amazonaws.com/${upload.container}/${upload.key.replace(/\+/g, '%2B').replace(/%20/g, '+')}`, true, true)+" target=\"_blank\"") + "\u003E" + (pug.escape(null == (pug_interp = upload.filename) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Cbr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var upload = $$obj[pug_index3];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", `https://s3.amazonaws.com/${upload.container}/${upload.key.replace(/\+/g, '%2B').replace(/%20/g, '+')}`, true, true)+" target=\"_blank\"") + "\u003E" + (pug.escape(null == (pug_interp = upload.filename) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Cbr\u003E";
    }
  }
}).call(this);

}
}
else
if (field.style.match(/\"kind\":\"url\"/mg)) {
if (field.value != "") {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", `${field.value}`, true, true)+" target=\"_blank\"") + "\u003E" + (pug.escape(null == (pug_interp = field.value) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
}
else {
pug_html = pug_html + (null == (pug_interp = field.value) ? "" : pug_interp);
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E";
if (record.activityData.serviceResponses) {
if (record.responseSuccess) {
pug_html = pug_html + "\u003Cdiv class=\"content text-center\"\u003E\u003Ci class=\"checkmark icon\"\u003E\u003C\u002Fi\u003E&nbsp;&nbsp;The task completed successfully\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"content text-center failed\"\u003E\u003Ci class=\"red warning sign icon\"\u003E\u003C\u002Fi\u003E&nbsp;The task failed...\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"content\"\u003E\u003Ctable class=\"ui very basic celled compact unstackable table\"\u003E\u003Ctbody\u003E";
// iterate record.activityData.serviceResponses
;(function(){
  var $$obj = record.activityData.serviceResponses;
  if ('number' == typeof $$obj.length) {
      for (var pug_index4 = 0, $$l = $$obj.length; pug_index4 < $$l; pug_index4++) {
        var response = $$obj[pug_index4];
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd class=\"six wide\"\u003E" + (pug.escape(null == (pug_interp = response.key) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003Ctd class=\"ten wide\"\u003E" + (null == (pug_interp = response.value) ? "" : pug_interp) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index4 in $$obj) {
      $$l++;
      var response = $$obj[pug_index4];
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd class=\"six wide\"\u003E" + (pug.escape(null == (pug_interp = response.key) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003Ctd class=\"ten wide\"\u003E" + (null == (pug_interp = response.value) ? "" : pug_interp) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"timeline-timestamp\"\u003E\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = record.date) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E,&nbsp;" + (pug.escape(null == (pug_interp = record.time) ? "" : pug_interp)) + "\u003Cdiv" + (pug.attr("class", pug.classes(["timeline-gravatar",`timeline-user-${record.userId}`], [false,true]), false, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var record = $$obj[pug_index0];
pug_html = pug_html + ("\u003Cdiv class=\"timeline-card\"\u003E\u003Cdiv class=\"timeline-img\"\u003E\u003Cimg" + (pug.attr("src", record.icon, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"timeline-content\"\u003E\u003Cdiv" + (" class=\"ui fluid raised card\""+pug.attr("id", `${record.activityData.templateActivityId}-${record.activityData.id}`, true, true)+pug.attr("data-clone", `${JSON.stringify(record.cloneTaskData)}`, true, true)) + "\u003E\u003Cdiv class=\"content\"\u003E\u003Cdiv class=\"header\"\u003E" + (pug.escape(null == (pug_interp = record.activityData.label) ? "" : pug_interp)));
if (record.activityData.serviceResponses) {
if (!(record.responseSuccess)) {
pug_html = pug_html + "\u003Cspan class=\"right floated\"\u003E\u003Ca" + (" class=\"mini red ui button retry-taskflow-js\""+pug.attr("href", `/api/group/${groupId}/process/${record.activityData.processId}/retry`, true, true)+" title=\"Retry the failed task within this taskflow\"") + "\u003ERetry?\u003C\u002Fa\u003E\u003C\u002Fspan\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"content\"\u003E\u003Ctable class=\"ui very basic celled compact unstackable table\"\u003E\u003Ctbody\u003E";
// iterate record.activityData.fields
;(function(){
  var $$obj = record.activityData.fields;
  if ('number' == typeof $$obj.length) {
      for (var pug_index5 = 0, $$l = $$obj.length; pug_index5 < $$l; pug_index5++) {
        var field = $$obj[pug_index5];
pug_html = pug_html + ("\u003Ctr" + (pug.attr("id", `${field.templateFieldId}-${field.id}`, true, true)) + "\u003E\u003Ctd class=\"six wide\"\u003E" + (pug.escape(null == (pug_interp = field.label) ? "" : pug_interp)));
if (!(field.required)) {
pug_html = pug_html + "&nbsp;\u003Cspan class=\"optional\"\u003E&nbsp;optional&nbsp;\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd class=\"ten wide\"\u003E";
if ((field.style.match(/\"kind\":\"upload\"/mg) || field.style.match(/\"kind\":\"uploadshow\"/mg))) {
if (field.value != "") {
var uploads = JSON.parse(field.value)
// iterate uploads.reverse()
;(function(){
  var $$obj = uploads.reverse();
  if ('number' == typeof $$obj.length) {
      for (var pug_index6 = 0, $$l = $$obj.length; pug_index6 < $$l; pug_index6++) {
        var upload = $$obj[pug_index6];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", `https://s3.amazonaws.com/${upload.container}/${upload.key.replace(/\+/g, '%2B').replace(/%20/g, '+')}`, true, true)+" target=\"_blank\"") + "\u003E" + (pug.escape(null == (pug_interp = upload.filename) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Cbr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index6 in $$obj) {
      $$l++;
      var upload = $$obj[pug_index6];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", `https://s3.amazonaws.com/${upload.container}/${upload.key.replace(/\+/g, '%2B').replace(/%20/g, '+')}`, true, true)+" target=\"_blank\"") + "\u003E" + (pug.escape(null == (pug_interp = upload.filename) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Cbr\u003E";
    }
  }
}).call(this);

}
}
else
if (field.style.match(/\"kind\":\"url\"/mg)) {
if (field.value != "") {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", `${field.value}`, true, true)+" target=\"_blank\"") + "\u003E" + (pug.escape(null == (pug_interp = field.value) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
}
else {
pug_html = pug_html + (null == (pug_interp = field.value) ? "" : pug_interp);
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index5 in $$obj) {
      $$l++;
      var field = $$obj[pug_index5];
pug_html = pug_html + ("\u003Ctr" + (pug.attr("id", `${field.templateFieldId}-${field.id}`, true, true)) + "\u003E\u003Ctd class=\"six wide\"\u003E" + (pug.escape(null == (pug_interp = field.label) ? "" : pug_interp)));
if (!(field.required)) {
pug_html = pug_html + "&nbsp;\u003Cspan class=\"optional\"\u003E&nbsp;optional&nbsp;\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd class=\"ten wide\"\u003E";
if ((field.style.match(/\"kind\":\"upload\"/mg) || field.style.match(/\"kind\":\"uploadshow\"/mg))) {
if (field.value != "") {
var uploads = JSON.parse(field.value)
// iterate uploads.reverse()
;(function(){
  var $$obj = uploads.reverse();
  if ('number' == typeof $$obj.length) {
      for (var pug_index7 = 0, $$l = $$obj.length; pug_index7 < $$l; pug_index7++) {
        var upload = $$obj[pug_index7];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", `https://s3.amazonaws.com/${upload.container}/${upload.key.replace(/\+/g, '%2B').replace(/%20/g, '+')}`, true, true)+" target=\"_blank\"") + "\u003E" + (pug.escape(null == (pug_interp = upload.filename) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Cbr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index7 in $$obj) {
      $$l++;
      var upload = $$obj[pug_index7];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", `https://s3.amazonaws.com/${upload.container}/${upload.key.replace(/\+/g, '%2B').replace(/%20/g, '+')}`, true, true)+" target=\"_blank\"") + "\u003E" + (pug.escape(null == (pug_interp = upload.filename) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Cbr\u003E";
    }
  }
}).call(this);

}
}
else
if (field.style.match(/\"kind\":\"url\"/mg)) {
if (field.value != "") {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", `${field.value}`, true, true)+" target=\"_blank\"") + "\u003E" + (pug.escape(null == (pug_interp = field.value) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
}
else {
pug_html = pug_html + (null == (pug_interp = field.value) ? "" : pug_interp);
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E";
if (record.activityData.serviceResponses) {
if (record.responseSuccess) {
pug_html = pug_html + "\u003Cdiv class=\"content text-center\"\u003E\u003Ci class=\"checkmark icon\"\u003E\u003C\u002Fi\u003E&nbsp;&nbsp;The task completed successfully\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"content text-center failed\"\u003E\u003Ci class=\"red warning sign icon\"\u003E\u003C\u002Fi\u003E&nbsp;The task failed...\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"content\"\u003E\u003Ctable class=\"ui very basic celled compact unstackable table\"\u003E\u003Ctbody\u003E";
// iterate record.activityData.serviceResponses
;(function(){
  var $$obj = record.activityData.serviceResponses;
  if ('number' == typeof $$obj.length) {
      for (var pug_index8 = 0, $$l = $$obj.length; pug_index8 < $$l; pug_index8++) {
        var response = $$obj[pug_index8];
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd class=\"six wide\"\u003E" + (pug.escape(null == (pug_interp = response.key) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003Ctd class=\"ten wide\"\u003E" + (null == (pug_interp = response.value) ? "" : pug_interp) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index8 in $$obj) {
      $$l++;
      var response = $$obj[pug_index8];
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd class=\"six wide\"\u003E" + (pug.escape(null == (pug_interp = response.key) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003Ctd class=\"ten wide\"\u003E" + (null == (pug_interp = response.value) ? "" : pug_interp) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"timeline-timestamp\"\u003E\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = record.date) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E,&nbsp;" + (pug.escape(null == (pug_interp = record.time) ? "" : pug_interp)) + "\u003Cdiv" + (pug.attr("class", pug.classes(["timeline-gravatar",`timeline-user-${record.userId}`], [false,true]), false, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003Cdiv class=\"timeline-end\"\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"JSON" in locals_for_with?locals_for_with.JSON:typeof JSON!=="undefined"?JSON:undefined,"groupId" in locals_for_with?locals_for_with.groupId:typeof groupId!=="undefined"?groupId:undefined,"loadingHistory" in locals_for_with?locals_for_with.loadingHistory:typeof loadingHistory!=="undefined"?loadingHistory:undefined,"records" in locals_for_with?locals_for_with.records:typeof records!=="undefined"?records:undefined));;return pug_html;};
module.exports = template;