var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (errorsDuringImport, hasErrors, importedTaskflows, referrerUri, totalRowsImported, totalTimeToImport) {pug_html = pug_html + "\u003Cdiv class=\"row\" id=\"importer-step-04\"\u003E\u003Cdiv class=\"five wide column\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"six wide column\"\u003E\u003Cdiv class=\"ui raised fluid card\"\u003E\u003Cdiv class=\"content\"\u003E\u003Ch3\u003EFinished\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E";
if (hasErrors) {
pug_html = pug_html + "\u003Cdiv class=\"content center aligned\"\u003E\u003Ci class=\"large warning sign orange icon\"\u003E\u003C\u002Fi\u003EYour .csv file has been imported but there were issues...\u003C\u002Fdiv\u003E\u003Cdiv class=\"content\"\u003E";
// iterate errorsDuringImport
;(function(){
  var $$obj = errorsDuringImport;
  if ('number' == typeof $$obj.length) {
      for (var errorDesc = 0, $$l = $$obj.length; errorDesc < $$l; errorDesc++) {
        var errorType = $$obj[errorDesc];
pug_html = pug_html + "\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = errorDesc) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E\u003Cbr\u003E";
// iterate errorType
;(function(){
  var $$obj = errorType;
  if ('number' == typeof $$obj.length) {
      for (var e = 0, $$l = $$obj.length; e < $$l; e++) {
        var err = $$obj[e];
pug_html = pug_html + "\u003Cem\u003E" + (pug.escape(null == (pug_interp = err) ? "" : pug_interp)) + "\u003C\u002Fem\u003E\u003Cbr\u003E";
      }
  } else {
    var $$l = 0;
    for (var e in $$obj) {
      $$l++;
      var err = $$obj[e];
pug_html = pug_html + "\u003Cem\u003E" + (pug.escape(null == (pug_interp = err) ? "" : pug_interp)) + "\u003C\u002Fem\u003E\u003Cbr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003Cbr\u003E";
      }
  } else {
    var $$l = 0;
    for (var errorDesc in $$obj) {
      $$l++;
      var errorType = $$obj[errorDesc];
pug_html = pug_html + "\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = errorDesc) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E\u003Cbr\u003E";
// iterate errorType
;(function(){
  var $$obj = errorType;
  if ('number' == typeof $$obj.length) {
      for (var e = 0, $$l = $$obj.length; e < $$l; e++) {
        var err = $$obj[e];
pug_html = pug_html + "\u003Cem\u003E" + (pug.escape(null == (pug_interp = err) ? "" : pug_interp)) + "\u003C\u002Fem\u003E\u003Cbr\u003E";
      }
  } else {
    var $$l = 0;
    for (var e in $$obj) {
      $$l++;
      var err = $$obj[e];
pug_html = pug_html + "\u003Cem\u003E" + (pug.escape(null == (pug_interp = err) ? "" : pug_interp)) + "\u003C\u002Fem\u003E\u003Cbr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003Cbr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"content center aligned\"\u003E\u003Ci class=\"large circle check green icon\"\u003E\u003C\u002Fi\u003E";
if (importedTaskflows) {
pug_html = pug_html + "Your taskflows have been queued and will be initiated shortly.\u003Cbr\u003E\u003Cbr\u003E\u003Cem\u003EDepending on the complexity of the taskflow we estimate that it will take\u003Cbr\u003E\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = totalTimeToImport) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E&nbsp;to kick-off \u003Cstrong\u003E" + (pug.escape(null == (pug_interp = totalRowsImported) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E&nbsp;taskflows...\u003C\u002Fem\u003E";
}
else {
pug_html = pug_html + "Your .csv file has been imported successfully.";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"content\"\u003E\u003Cdiv class=\"right floated\"\u003E\u003Ca" + (" class=\"ui right labeled icon green disabled button\""+" id=\"finishImportBtn\""+pug.attr("href", `${referrerUri}`, true, true)) + "\u003E\u003Ci class=\"right arrow icon\"\u003E\u003C\u002Fi\u003EFinish\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"five wide column\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"errorsDuringImport" in locals_for_with?locals_for_with.errorsDuringImport:typeof errorsDuringImport!=="undefined"?errorsDuringImport:undefined,"hasErrors" in locals_for_with?locals_for_with.hasErrors:typeof hasErrors!=="undefined"?hasErrors:undefined,"importedTaskflows" in locals_for_with?locals_for_with.importedTaskflows:typeof importedTaskflows!=="undefined"?importedTaskflows:undefined,"referrerUri" in locals_for_with?locals_for_with.referrerUri:typeof referrerUri!=="undefined"?referrerUri:undefined,"totalRowsImported" in locals_for_with?locals_for_with.totalRowsImported:typeof totalRowsImported!=="undefined"?totalRowsImported:undefined,"totalTimeToImport" in locals_for_with?locals_for_with.totalTimeToImport:typeof totalTimeToImport!=="undefined"?totalTimeToImport:undefined));;return pug_html;};
module.exports = template;