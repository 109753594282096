var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (peopleList, rescheduleList, todo) {pug_html = pug_html + "\u003Ci class=\"close close-popup icon\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"header\"\u003E";
if (!(todo.id)) {
pug_html = pug_html + "Add a to-do";
}
else {
pug_html = pug_html + "Update a to-do";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"description\"\u003E\u003Cdiv class=\"kotive-task-body kotive-task-body-no-padding\"\u003E\u003Cform class=\"ui form\"\u003E\u003Cinput" + (" name=\"id\" type=\"hidden\""+pug.attr("value", `${todo.id === undefined ? '' : todo.id}`, true, true)) + "\u003E\u003Cinput" + (" name=\"status\" type=\"hidden\""+pug.attr("value", `${todo.status === undefined ? 'incomplete' : todo.status}`, true, true)) + "\u003E\u003Cdiv class=\"field kotive-field-input\"\u003E\u003Clabel for=\"label\"\u003EWhat needs to be done?\u003C\u002Flabel\u003E\u003Ctextarea id=\"label\" name=\"label\" rows=\"2\"\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Ftextarea\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"field kotive-field-input\"\u003E\u003Clabel for=\"assignedTo\"\u003EWho needs to do it?\u003C\u002Flabel\u003E\u003Cdiv class=\"ui fluid multiple search selection dropdown\" id=\"todo-assignedTo-dropdown-js\"\u003E\u003Cinput" + (" name=\"assignedTo\" type=\"hidden\""+pug.attr("value", `${todo.assignedTo}`, true, true)) + "\u003E\u003Ci class=\"dropdown icon\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"default text\"\u003EAssign this to-do to someone...\u003C\u002Fdiv\u003E\u003Cdiv class=\"menu\"\u003E";
// iterate peopleList
;(function(){
  var $$obj = peopleList;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var person = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv" + (" class=\"item selected\""+pug.attr("data-value", person.id, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = `${person.firstname} ${person.lastname}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var person = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv" + (" class=\"item selected\""+pug.attr("data-value", person.id, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = `${person.firstname} ${person.lastname}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"field kotive-field-input\" id=\"todo-due-js\"\u003E\u003Clabel for=\"dueAt\"\u003EWhen is it due?\u003C\u002Flabel\u003E\u003Cdiv class=\"ui calendar\" id=\"datepicker\"\u003E\u003Cdiv class=\"ui input\"\u003E\u003Cinput" + (" id=\"dueAt\" name=\"dueAt\" type=\"text\""+pug.attr("value", `${todo.dueAt}`, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"field kotive-field-input\" id=\"todo-overdue-js\"\u003E\u003Clabel for=\"overdueAction\"\u003EWhen the to-do becomes overdue...\u003C\u002Flabel\u003E\u003Cdiv class=\"clearfix\"\u003E\u003C\u002Fdiv\u003E";
var overdueActionEnabled = (todo.overdueAction == 'enabled')
var overdueActionDisabled = (todo.overdueAction == 'disabled')
pug_html = pug_html + "\u003Clabel class=\"radio-option-inline\"\u003E\u003Cinput" + (" type=\"radio\" id=\"overdueAction\" name=\"overdueAction\" value=\"enabled\""+pug.attr("checked", overdueActionEnabled, true, true)) + "\u003E&nbsp;People can still make changes to it \u003Cem\u003E(default)\u003C\u002Fem\u003E\u003C\u002Flabel\u003E\u003Clabel class=\"radio-option-inline\"\u003E\u003Cinput" + (" type=\"radio\" id=\"overdueAction\" name=\"overdueAction\" value=\"disabled\""+pug.attr("checked", overdueActionDisabled, true, true)) + "\u003E&nbsp;Nobody can make any changes to it\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"field kotive-field-input\" id=\"todo-reschedule-js\"\u003E\u003Cspan class=\"pull-right\"\u003E\u003Ci class=\"info circle green icon\" title=\"When reaching the due date, a copy of this to-do will automatically be created and its due date will be set based on the duration\u002Ffrequency chosen in this list.\"\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003Clabel for=\"reschedule\"\u003EHow often should this to-do be repeated?\u003C\u002Flabel\u003E\u003Cdiv class=\"ui fluid search selection dropdown\" id=\"todo-reschedule-dropdown-js\"\u003E\u003Cinput" + (" name=\"reschedule\" type=\"hidden\""+pug.attr("value", `${todo.reschedule}`, true, true)) + "\u003E\u003Ci class=\"dropdown icon\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"default text\"\u003EAuto-reschedule this to-do...\u003C\u002Fdiv\u003E\u003Cdiv class=\"menu\"\u003E";
// iterate rescheduleList
;(function(){
  var $$obj = rescheduleList;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var item = $$obj[pug_index1];
pug_html = pug_html + "\u003Cdiv" + (" class=\"item selected\""+pug.attr("data-value", item, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = `${item}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var item = $$obj[pug_index1];
pug_html = pug_html + "\u003Cdiv" + (" class=\"item selected\""+pug.attr("data-value", item, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = `${item}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"field kotive-field-input\"\u003E\u003Clabel for=\"inform\"\u003EWho should be notified by email?\u003C\u002Flabel\u003E\u003Cdiv class=\"clearfix\"\u003E\u003C\u002Fdiv\u003E";
var informIsNone = (todo.inform === 'none')
var informIsCreatedBy = (todo.inform === 'createdBy')
var informIsAssignedTo = (todo.inform === 'assignedTo')
var informIsAll = (todo.inform === 'all')
pug_html = pug_html + "\u003Clabel class=\"radio-option-inline\"\u003E\u003Cinput" + (" type=\"radio\" id=\"inform\" name=\"inform\" value=\"none\""+pug.attr("checked", informIsNone, true, true)) + "\u003E&nbsp;Nobody\u003C\u002Flabel\u003E\u003Clabel class=\"radio-option-inline\"\u003E\u003Cinput" + (" type=\"radio\" id=\"inform\" name=\"inform\" value=\"assignedTo\""+pug.attr("checked", informIsAssignedTo, true, true)) + "\u003E&nbsp;People assigned to the to-do\u003C\u002Flabel\u003E\u003Clabel class=\"radio-option-inline\"\u003E\u003Cinput" + (" type=\"radio\" id=\"inform\" name=\"inform\" value=\"createdBy\""+pug.attr("checked", informIsCreatedBy, true, true)) + "\u003E&nbsp;The to-do creator (on completion)\u003C\u002Flabel\u003E\u003Clabel class=\"radio-option-inline\"\u003E\u003Cinput" + (" type=\"radio\" id=\"inform\" name=\"inform\" value=\"all\""+pug.attr("checked", informIsAll, true, true)) + "\u003E&nbsp;The to-do creator &amp; people assigned to it\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"extra content\"\u003E\u003Cdiv class=\"pull-right\"\u003E\u003Cdiv class=\"ui button green todo-save-btn-js\"\u003ESave to-do\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"peopleList" in locals_for_with?locals_for_with.peopleList:typeof peopleList!=="undefined"?peopleList:undefined,"rescheduleList" in locals_for_with?locals_for_with.rescheduleList:typeof rescheduleList!=="undefined"?rescheduleList:undefined,"todo" in locals_for_with?locals_for_with.todo:typeof todo!=="undefined"?todo:undefined));;return pug_html;};
module.exports = template;