var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (config, matrix) {pug_html = pug_html + "\u003Cdiv" + (pug.attr("id", `${config.selector}`, true, true)) + "\u003E\u003Cdiv class=\"ui grid\"\u003E\u003Cdiv class=\"row\" style=\"padding-bottom: 0px;\"\u003E\u003Cdiv class=\"one wide column text-vertical center aligned\"\u003E" + (null == (pug_interp = config.options.yAxis.title) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"fifteen wide column center aligned\" style=\"padding-left:0px;\"\u003E\u003Ch3\u003E" + (pug.escape(null == (pug_interp = config.options.title) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\u003Ctable class=\"ui table fixed celled structured small matrix-table matrix-all\"\u003E\u003Ctbody\u003E";
// iterate config.options.yAxis.labels
;(function(){
  var $$obj = config.options.yAxis.labels;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var yLabel = $$obj[pug_index0];
pug_html = pug_html + "\u003Ctr class=\"center aligned\"\u003E\u003Ctd class=\"matrix-header\"\u003E" + (pug.escape(null == (pug_interp = yLabel) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
// iterate config.options.xAxis.labels
;(function(){
  var $$obj = config.options.xAxis.labels;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var xLabel = $$obj[pug_index1];
pug_html = pug_html + "\u003Ctd" + (pug.attr("class", pug.classes([`matrix-${matrix[yLabel][xLabel].color}`], [true]), false, true)+pug.attr("data-composite", `${yLabel}_${xLabel}`, true, true)+pug.attr("data-y", `${yLabel}`, true, true)+pug.attr("data-x", `${xLabel}`, true, true)+pug.attr("data-color", `${matrix[yLabel][xLabel].color}`, true, true)) + "\u003E\u003C\u002Ftd\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var xLabel = $$obj[pug_index1];
pug_html = pug_html + "\u003Ctd" + (pug.attr("class", pug.classes([`matrix-${matrix[yLabel][xLabel].color}`], [true]), false, true)+pug.attr("data-composite", `${yLabel}_${xLabel}`, true, true)+pug.attr("data-y", `${yLabel}`, true, true)+pug.attr("data-x", `${xLabel}`, true, true)+pug.attr("data-color", `${matrix[yLabel][xLabel].color}`, true, true)) + "\u003E\u003C\u002Ftd\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var yLabel = $$obj[pug_index0];
pug_html = pug_html + "\u003Ctr class=\"center aligned\"\u003E\u003Ctd class=\"matrix-header\"\u003E" + (pug.escape(null == (pug_interp = yLabel) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
// iterate config.options.xAxis.labels
;(function(){
  var $$obj = config.options.xAxis.labels;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var xLabel = $$obj[pug_index2];
pug_html = pug_html + "\u003Ctd" + (pug.attr("class", pug.classes([`matrix-${matrix[yLabel][xLabel].color}`], [true]), false, true)+pug.attr("data-composite", `${yLabel}_${xLabel}`, true, true)+pug.attr("data-y", `${yLabel}`, true, true)+pug.attr("data-x", `${xLabel}`, true, true)+pug.attr("data-color", `${matrix[yLabel][xLabel].color}`, true, true)) + "\u003E\u003C\u002Ftd\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var xLabel = $$obj[pug_index2];
pug_html = pug_html + "\u003Ctd" + (pug.attr("class", pug.classes([`matrix-${matrix[yLabel][xLabel].color}`], [true]), false, true)+pug.attr("data-composite", `${yLabel}_${xLabel}`, true, true)+pug.attr("data-y", `${yLabel}`, true, true)+pug.attr("data-x", `${xLabel}`, true, true)+pug.attr("data-color", `${matrix[yLabel][xLabel].color}`, true, true)) + "\u003E\u003C\u002Ftd\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003Ctr class=\"center aligned\"\u003E\u003Ctd class=\"matrix-empty\"\u003E\u003C\u002Ftd\u003E";
// iterate config.options.xAxis.labels
;(function(){
  var $$obj = config.options.xAxis.labels;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var label = $$obj[pug_index3];
pug_html = pug_html + "\u003Ctd class=\"matrix-header\"\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var label = $$obj[pug_index3];
pug_html = pug_html + "\u003Ctd class=\"matrix-header\"\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftr\u003E\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"one wide column\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"fifteen wide column center aligned\"\u003E" + (null == (pug_interp = config.options.xAxis.title) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"config" in locals_for_with?locals_for_with.config:typeof config!=="undefined"?config:undefined,"matrix" in locals_for_with?locals_for_with.matrix:typeof matrix!=="undefined"?matrix:undefined));;return pug_html;};
module.exports = template;