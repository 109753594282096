var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (person) {pug_html = pug_html + "\u003Cdiv class=\"ui fluid raised card\" id=\"person-badge\"\u003E\u003Cdiv class=\"content text-center\"\u003E\u003Cspan class=\"right floated\"\u003E\u003Ca class=\"person-update-js hidden\" data-target=\"modal-person-update\"\u003E\u003Ci class=\"write icon\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fspan\u003E\u003Cbr\u003E\u003Cimg" + (" class=\"ui tiny circular image\""+pug.attr("src", person.gravatar, true, true)) + "\u003E\u003Cbr\u003E\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = person.displayName) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E\u003Cbr\u003E";
if (person.email) {
pug_html = pug_html + "\u003Ci class=\"mail outline icon\"\u003E\u003C\u002Fi\u003E\u003Ca" + (pug.attr("href", `mailto:${person.email}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = person.email) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
if (person.mobile) {
pug_html = pug_html + "\u003Cbr\u003E\u003Ci class=\"mobile icon\"\u003E\u003C\u002Fi\u003E\u003Ca" + (pug.attr("href", `tel:${person.mobile}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = person.mobile) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv id=\"person-badge-roles\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"person" in locals_for_with?locals_for_with.person:typeof person!=="undefined"?person:undefined));;return pug_html;};
module.exports = template;