var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (count, selectedTags, tagList) {pug_html = pug_html + "\u003Ci class=\"close close-popup icon\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"header\"\u003E";
if (!(tagList)) {
pug_html = pug_html + "There's nothing to tag...";
}
else {
if (count == 1) {
pug_html = pug_html + "Tag or untag the selected item";
}
else {
pug_html = pug_html + (pug.escape(null == (pug_interp = `Tag or untag the ${count} selected items`) ? "" : pug_interp));
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"description\"\u003E";
if (!(tagList)) {
pug_html = pug_html + "\u003Cp\u003EYou first need to tick the box to the left of each item you want to tag.\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "Tag or untag the selected items\u003Cdiv class=\"ui fluid multiple search selection dropdown\" id=\"tag-chooser-dropdown-js\"\u003E\u003Cinput" + (" name=\"tags\" type=\"hidden\""+pug.attr("value", `${selectedTags}`, true, true)) + "\u003E\u003Ci class=\"dropdown icon\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"default text\"\u003EChoose or define tags...\u003C\u002Fdiv\u003E\u003Cdiv class=\"menu\"\u003E";
// iterate tagList
;(function(){
  var $$obj = tagList;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var label = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv" + (" class=\"item selected\""+pug.attr("data-value", label, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var label = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv" + (" class=\"item selected\""+pug.attr("data-value", label, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (tagList) {
pug_html = pug_html + "\u003Cdiv class=\"extra content\"\u003E\u003Cdiv class=\"pull-right\"\u003E\u003Cdiv class=\"ui button green tag-btn-js\"\u003E\u003Ci class=\"icon tags\"\u003E\u003C\u002Fi\u003EUpdate tags\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}}.call(this,"count" in locals_for_with?locals_for_with.count:typeof count!=="undefined"?count:undefined,"selectedTags" in locals_for_with?locals_for_with.selectedTags:typeof selectedTags!=="undefined"?selectedTags:undefined,"tagList" in locals_for_with?locals_for_with.tagList:typeof tagList!=="undefined"?tagList:undefined));;return pug_html;};
module.exports = template;