var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (fields, tags) {pug_html = pug_html + "\u003Cdiv class=\"risk-statement-box\" id=\"risk-statement-description\"\u003E\u003Cdiv class=\"ui grid\"\u003E\u003Cdiv class=\"one wide column\"\u003E\u003Ci class=\"green info circle big icon\"\u003E\u003C\u002Fi\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"fifteen wide column\"\u003E\u003Ch3\u003E";
if (fields.ref) {
pug_html = pug_html + (pug.escape(null == (pug_interp = `${fields.ref} `) ? "" : pug_interp));
}
pug_html = pug_html + (null == (pug_interp = fields.statement) ? "" : pug_interp) + "\u003C\u002Fh3\u003E";
if (fields.description) {
pug_html = pug_html + (null == (pug_interp = fields.description) ? "" : pug_interp);
}
if ((fields.criteriarole == 'Root')) {
pug_html = pug_html + "\u003Cp\u003E\u003Cem\u003E" + (pug.escape(null == (pug_interp = `This is a ${fields.criteriarole} criterion and will be auto-rated.`) ? "" : pug_interp)) + "\u003C\u002Fem\u003E\u003C\u002Fp\u003E";
}
if (fields.severity) {
pug_html = pug_html + "\u003Cp\u003E\u003Cstrong\u003ESeverity:\u003C\u002Fstrong\u003E" + (pug.escape(null == (pug_interp = ` ${fields.severity}`) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
if (fields.externalRefs) {
pug_html = pug_html + "\u003Cp\u003E" + (null == (pug_interp = fields.externalRefs) ? "" : pug_interp) + "\u003C\u002Fp\u003E";
}
if (fields.templates) {
pug_html = pug_html + "\u003Cp\u003E\u003Cstrong\u003ETemplates\u003C\u002Fstrong\u003E\u003Cbr\u003E" + (null == (pug_interp = fields.templates) ? "" : pug_interp) + "\u003C\u002Fp\u003E";
}
if (fields.linkedCriteria) {
pug_html = pug_html + ("\u003Cp\u003E\u003Cstrong\u003E\u003Cem\u003ELinked Criteria:\u003C\u002Fem\u003E\u003C\u002Fstrong\u003E\u003C\u002Fp\u003E" + (null == (pug_interp = fields.linkedCriteria) ? "" : pug_interp));
}
pug_html = pug_html + (null == (pug_interp = tags) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"fields" in locals_for_with?locals_for_with.fields:typeof fields!=="undefined"?fields:undefined,"tags" in locals_for_with?locals_for_with.tags:typeof tags!=="undefined"?tags:undefined));;return pug_html;};
module.exports = template;