var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (groupId, kanban, taskflowId, window) {var activeUrl = window.location.hash.substr(2)
pug_html = pug_html + "\u003Cdiv class=\"ui selection dropdown\" id=\"switch-view-js\"\u003E\u003Cdiv class=\"text\"\u003E";
if ((activeUrl == `/${groupId}/report/${taskflowId}/`)) {
pug_html = pug_html + "\u003Ci class=\"list alternate outline icon\"\u003E\u003C\u002Fi\u003ETable view";
}
else
if ((activeUrl == `/${groupId}/kanban/${taskflowId}/`)) {
pug_html = pug_html + ("\u003Ci class=\"columns icon\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = kanban.label) ? "" : pug_interp)));
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Ci class=\"dropdown icon\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"menu\"\u003E\u003Cdiv class=\"header\"\u003EChoose another view\u003C\u002Fdiv\u003E";
if ((activeUrl == `/${groupId}/report/${taskflowId}/`)) {
pug_html = pug_html + "\u003Cdiv class=\"item active\"\u003E\u003Ci class=\"list alternate outline icon\"\u003E\u003C\u002Fi\u003ETable view\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"item\""+pug.attr("href", `/hub/${groupId}/kanban/${taskflowId}/`, true, true)) + "\u003E\u003Ci class=\"columns icon\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = kanban.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
else
if ((activeUrl == `/${groupId}/kanban/${taskflowId}/`)) {
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", `/hub/${groupId}/report/${taskflowId}/`, true, true)) + "\u003E\u003Ci class=\"list alternate outline icon\"\u003E\u003C\u002Fi\u003ETable view\u003C\u002Fa\u003E\u003Cdiv class=\"item active\"\u003E\u003Ci class=\"columns icon\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = kanban.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"groupId" in locals_for_with?locals_for_with.groupId:typeof groupId!=="undefined"?groupId:undefined,"kanban" in locals_for_with?locals_for_with.kanban:typeof kanban!=="undefined"?kanban:undefined,"taskflowId" in locals_for_with?locals_for_with.taskflowId:typeof taskflowId!=="undefined"?taskflowId:undefined,"window" in locals_for_with?locals_for_with.window:typeof window!=="undefined"?window:undefined));;return pug_html;};
module.exports = template;