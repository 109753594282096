var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (bespokeMetaData, htmlContent, isLoading) {pug_html = pug_html + "\u003Cdiv id=\"workarea\"\u003E\u003Cdiv class=\"ui grid\"\u003E\u003Cdiv class=\"column\"\u003E\u003Cdiv class=\"ui segments\"\u003E\u003Cdiv class=\"ui segment\"\u003E\u003Cdiv class=\"pull-left\"\u003E\u003Ch2 id=\"page-title-js\"\u003E";
if (bespokeMetaData && bespokeMetaData.label) {
pug_html = pug_html + (pug.escape(null == (pug_interp = bespokeMetaData.label) ? "" : pug_interp));
}
pug_html = pug_html + "\u003C\u002Fh2\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"clearfix\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (bespokeMetaData && bespokeMetaData.description) {
pug_html = pug_html + "\u003Cdiv class=\"ui segment\"\u003E" + (null == (pug_interp = bespokeMetaData.description) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
if (isLoading) {
pug_html = pug_html + "\u003Cdiv class=\"ui segment\"\u003E\u003Cimg" + (" class=\"ui centered image\""+pug.attr("src", require('../../images/loading.gif'), true, true)+" alt=\"\"") + "\u003E\u003C\u002Fdiv\u003E";
}
if (htmlContent) {
pug_html = pug_html + (null == (pug_interp = htmlContent) ? "" : pug_interp);
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"bespokeMetaData" in locals_for_with?locals_for_with.bespokeMetaData:typeof bespokeMetaData!=="undefined"?bespokeMetaData:undefined,"htmlContent" in locals_for_with?locals_for_with.htmlContent:typeof htmlContent!=="undefined"?htmlContent:undefined,"isLoading" in locals_for_with?locals_for_with.isLoading:typeof isLoading!=="undefined"?isLoading:undefined));;return pug_html;};
module.exports = template;