var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (activities, component) {if (!(activities.length > 0)) {
pug_html = pug_html + "\u003Cdiv class=\"content text-center\"\u003E\u003Cspan class=\"yellow-strip\"\u003ENo information is available...\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"content\"\u003E\u003Ctable class=\"ui very basic compact unstackable table keyval\"\u003E\u003Ctbody\u003E";
// iterate component.columns
;(function(){
  var $$obj = component.columns;
  if ('number' == typeof $$obj.length) {
      for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
        var column = $$obj[i];
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = column.label) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E\u003C\u002Ftd\u003E\u003Ctd\u003E" + (null == (pug_interp = activities[0].fields[i]) ? "" : pug_interp) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;
      var column = $$obj[i];
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = column.label) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E\u003C\u002Ftd\u003E\u003Ctd\u003E" + (null == (pug_interp = activities[0].fields[i]) ? "" : pug_interp) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E";
}}.call(this,"activities" in locals_for_with?locals_for_with.activities:typeof activities!=="undefined"?activities:undefined,"component" in locals_for_with?locals_for_with.component:typeof component!=="undefined"?component:undefined));;return pug_html;};
module.exports = template;