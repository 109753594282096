var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (groupId, isRefreshed, item, permissions, taskflowId, template) {var refresh = isRefreshed ? 'refreshed' : ''
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["ui","raised","card",refresh], [false,false,false,true]), false, true)+pug.attr("data-process-id", item.processId, true, true)) + "\u003E\u003Cdiv class=\"content\"\u003E";
if (template.fields[0]) {
pug_html = pug_html + "\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = item.fields[template.fields[0].templateFieldId]) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"meta\"\u003E\u003Cspan class=\"right floated\"\u003E" + (pug.escape(null == (pug_interp = item.humanTimeStamp) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = item.user.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
if (template.fields.length > 1) {
pug_html = pug_html + "\u003Cdiv class=\"description\"\u003E";
// iterate template.fields
;(function(){
  var $$obj = template.fields;
  if ('number' == typeof $$obj.length) {
      for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
        var field = $$obj[i];
if (i > 0) {
pug_html = pug_html + "\u003Cp\u003E\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = field.label) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E\u003Cbr\u003E";
if (item.fields[field.templateFieldId]) {
pug_html = pug_html + (pug.escape(null == (pug_interp = item.fields[field.templateFieldId]) ? "" : pug_interp));
}
else {
pug_html = pug_html + "-";
}
pug_html = pug_html + "\u003C\u002Fp\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;
      var field = $$obj[i];
if (i > 0) {
pug_html = pug_html + "\u003Cp\u003E\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = field.label) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E\u003Cbr\u003E";
if (item.fields[field.templateFieldId]) {
pug_html = pug_html + (pug.escape(null == (pug_interp = item.fields[field.templateFieldId]) ? "" : pug_interp));
}
else {
pug_html = pug_html + "-";
}
pug_html = pug_html + "\u003C\u002Fp\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"content\"\u003E\u003Cspan class=\"right floated\"\u003E";
if (permissions.continue) {
if (item.phase != "done") {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", `/hub/taskflow/continue/${item.groupId}/${item.processId}/modal`, true, true)+" title=\"Continue\" data-target=\"modal-taskflow\""+pug.attr("data-referrer", `/${groupId}/kanban/${taskflowId}`, true, true)) + "\u003EContinue &rarr;\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Ci class=\"green check icon\"\u003E\u003C\u002Fi\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fspan\u003E\u003Cspan\u003E";
if (permissions.audits) {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", `${taskflowId}-${item.processId}`, true, true)+" title=\"View its history\" data-target=\"modal-taskflow-history\"") + "\u003EHistory\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"groupId" in locals_for_with?locals_for_with.groupId:typeof groupId!=="undefined"?groupId:undefined,"isRefreshed" in locals_for_with?locals_for_with.isRefreshed:typeof isRefreshed!=="undefined"?isRefreshed:undefined,"item" in locals_for_with?locals_for_with.item:typeof item!=="undefined"?item:undefined,"permissions" in locals_for_with?locals_for_with.permissions:typeof permissions!=="undefined"?permissions:undefined,"taskflowId" in locals_for_with?locals_for_with.taskflowId:typeof taskflowId!=="undefined"?taskflowId:undefined,"template" in locals_for_with?locals_for_with.template:typeof template!=="undefined"?template:undefined));;return pug_html;};
module.exports = template;