var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (groupId, hasTodos, loading, renderMode, title, todos, todosMetadata) {pug_html = pug_html + "\u003Cdiv class=\"ui raised fluid card\" id=\"todos-card\" style=\"word-wrap:anywhere;\"\u003E";
if (loading) {
pug_html = pug_html + "\u003Cdiv class=\"content text-center\"\u003E\u003Cdiv class=\"ui active centered inline small grey loader\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"content\"\u003E\u003Ch3\u003E";
if ((renderMode == 'groupByDueDate')) {
pug_html = pug_html + (pug.escape(null == (pug_interp = title) ? "" : pug_interp)) + "\u003Cspan class=\"right floated\"\u003E\u003Ca class=\"ui mini basic compact button\" id=\"todo-bypeople-btn-js\"\u003E\u003Ci class=\"user circle icon\"\u003E\u003C\u002Fi\u003EGroup To-dos by People\u003C\u002Fa\u003E";
if ((todosMetadata.group !== undefined) && (todosMetadata.group.showAddBtn)) {
pug_html = pug_html + "&nbsp;\u003Ca class=\"ui icon mini green compact button todos-add\"\u003E\u003Ci class=\"plus icon\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fspan\u003E";
}
else
if ((renderMode == 'groupByPerson')) {
pug_html = pug_html + (pug.escape(null == (pug_interp = title) ? "" : pug_interp)) + "\u003Cspan class=\"right floated\"\u003E\u003Ca class=\"ui mini basic compact button\" id=\"todo-byduedate-btn-js\"\u003E\u003Ci class=\"calendar alternate icon\"\u003E\u003C\u002Fi\u003EGroup To-dos by date\u003C\u002Fa\u003E";
if ((todosMetadata.group !== undefined) && (todosMetadata.group.showAddBtn)) {
pug_html = pug_html + "&nbsp;\u003Ca class=\"ui icon mini green compact button todos-add\"\u003E\u003Ci class=\"plus icon\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + (pug.escape(null == (pug_interp = title) ? "" : pug_interp));
if ((todosMetadata.person !== undefined) && (todosMetadata.person.showAddBtn)) {
pug_html = pug_html + "\u003Cspan class=\"right floated\"\u003E\u003Ca class=\"ui mini green compact button todos-add\"\u003E\u003Ci class=\"plus icon\"\u003E\u003C\u002Fi\u003EAdd new to-do\u003C\u002Fa\u003E\u003C\u002Fspan\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E";
if (hasTodos) {
pug_html = pug_html + "\u003Cdiv class=\"content\"\u003E\u003Ctable class=\"ui very basic table\"\u003E\u003Ctbody\u003E";
if ((renderMode == 'personByDueDate') || (renderMode == 'groupByDueDate')) {
// iterate todos
;(function(){
  var $$obj = todos;
  if ('number' == typeof $$obj.length) {
      for (var bucket = 0, $$l = $$obj.length; bucket < $$l; bucket++) {
        var todoList = $$obj[bucket];
pug_html = pug_html + "\u003Ctr class=\"top aligned\"\u003E\u003Ctd\u003E";
if (bucket == 'overdue') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"color: #f2711c; font-weight: 400;\"\u003EOverdue\u003C\u002Fdiv\u003E";
}
else
if (bucket == 'withoutDueDate') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400;\"\u003EWithout due date\u003C\u002Fdiv\u003E";
}
else
if (bucket == 'complete') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400;\"\u003ECompleted\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400;\"\u003E" + (pug.escape(null == (pug_interp = bucket) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd\u003E";
// iterate todoList
;(function(){
  var $$obj = todoList;
  if ('number' == typeof $$obj.length) {
      for (var t = 0, $$l = $$obj.length; t < $$l; t++) {
        var todo = $$obj[t];
todo.isHidden = (t > 4) ? true : false
if (todo.properties) {
if (todo.properties.link) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)) + "\u003E\u003Ca" + (pug.attr("href", todo.properties.link, true, true)+pug.attr("data-target", todo.properties.target, true, true)+pug.attr("data-autoclose", todo.properties.autoclose, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isDisabled ? 'todo-disabled' : ''} ${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)+pug.attr("title", `${todo.isDisabled ? 'This to-do has been deactivated and cannot be updated.' : ''}`, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Cspan" + (pug.attr("class", pug.classes([`todos-status-${todo.status}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if ((renderMode == 'groupByDueDate')) {
pug_html = pug_html + "&nbsp;";
if ((todo.assignedTo.length > 0)) {
// iterate todo.assignedTo
;(function(){
  var $$obj = todo.assignedTo;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var assignedToId = $$obj[pug_index2];
pug_html = pug_html + "\u003Cspan" + (pug.attr("class", pug.classes(["ui","mini","label","hidden",`todo-assignedToId-${assignedToId}`], [false,false,false,false,true]), false, true)) + "\u003E\u003C\u002Fspan\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var assignedToId = $$obj[pug_index2];
pug_html = pug_html + "\u003Cspan" + (pug.attr("class", pug.classes(["ui","mini","label","hidden",`todo-assignedToId-${assignedToId}`], [false,false,false,false,true]), false, true)) + "\u003E\u003C\u002Fspan\u003E";
    }
  }
}).call(this);

}
}
if ((todo.reschedule != 'Never')) {
pug_html = pug_html + "&nbsp;\u003Cspan class=\"ui mini label\"\u003E\u003Ci class=\"sync alternate icon\"\u003E\u003C\u002Fi\u003ERepeated " + (pug.escape(null == (pug_interp = todo.reschedule) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if ((todo.dueAtFormatted && ((bucket == 'overdue') || (bucket == 'complete')))) {
pug_html = pug_html + "\u003Csmall class=\"yellow-strip no-wrap\"\u003E" + (pug.escape(null == (pug_interp = todo.dueAtFormatted) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var t in $$obj) {
      $$l++;
      var todo = $$obj[t];
todo.isHidden = (t > 4) ? true : false
if (todo.properties) {
if (todo.properties.link) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)) + "\u003E\u003Ca" + (pug.attr("href", todo.properties.link, true, true)+pug.attr("data-target", todo.properties.target, true, true)+pug.attr("data-autoclose", todo.properties.autoclose, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isDisabled ? 'todo-disabled' : ''} ${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)+pug.attr("title", `${todo.isDisabled ? 'This to-do has been deactivated and cannot be updated.' : ''}`, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Cspan" + (pug.attr("class", pug.classes([`todos-status-${todo.status}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if ((renderMode == 'groupByDueDate')) {
pug_html = pug_html + "&nbsp;";
if ((todo.assignedTo.length > 0)) {
// iterate todo.assignedTo
;(function(){
  var $$obj = todo.assignedTo;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var assignedToId = $$obj[pug_index3];
pug_html = pug_html + "\u003Cspan" + (pug.attr("class", pug.classes(["ui","mini","label","hidden",`todo-assignedToId-${assignedToId}`], [false,false,false,false,true]), false, true)) + "\u003E\u003C\u002Fspan\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var assignedToId = $$obj[pug_index3];
pug_html = pug_html + "\u003Cspan" + (pug.attr("class", pug.classes(["ui","mini","label","hidden",`todo-assignedToId-${assignedToId}`], [false,false,false,false,true]), false, true)) + "\u003E\u003C\u002Fspan\u003E";
    }
  }
}).call(this);

}
}
if ((todo.reschedule != 'Never')) {
pug_html = pug_html + "&nbsp;\u003Cspan class=\"ui mini label\"\u003E\u003Ci class=\"sync alternate icon\"\u003E\u003C\u002Fi\u003ERepeated " + (pug.escape(null == (pug_interp = todo.reschedule) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if ((todo.dueAtFormatted && ((bucket == 'overdue') || (bucket == 'complete')))) {
pug_html = pug_html + "\u003Csmall class=\"yellow-strip no-wrap\"\u003E" + (pug.escape(null == (pug_interp = todo.dueAtFormatted) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
    }
  }
}).call(this);

if ((todoList.length > 4)) {
pug_html = pug_html + "\u003Cbr\u003E\u003Ca class=\"ui mini right labeled icon basic blue button pull-right\" id=\"todo-showmore-js\"\u003E\u003Ci class=\"down arrow icon\"\u003E\u003C\u002Fi\u003EShow " + (pug.escape(null == (pug_interp = todoList.length - 4) ? "" : pug_interp)) + " more to-dos...\u003C\u002Fa\u003E\u003Ca class=\"ui mini right labeled icon basic blue button hidden pull-right\" id=\"todo-showless-js\"\u003E\u003Ci class=\"up arrow icon\"\u003E\u003C\u002Fi\u003EShow less to-dos...\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var bucket in $$obj) {
      $$l++;
      var todoList = $$obj[bucket];
pug_html = pug_html + "\u003Ctr class=\"top aligned\"\u003E\u003Ctd\u003E";
if (bucket == 'overdue') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"color: #f2711c; font-weight: 400;\"\u003EOverdue\u003C\u002Fdiv\u003E";
}
else
if (bucket == 'withoutDueDate') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400;\"\u003EWithout due date\u003C\u002Fdiv\u003E";
}
else
if (bucket == 'complete') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400;\"\u003ECompleted\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400;\"\u003E" + (pug.escape(null == (pug_interp = bucket) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd\u003E";
// iterate todoList
;(function(){
  var $$obj = todoList;
  if ('number' == typeof $$obj.length) {
      for (var t = 0, $$l = $$obj.length; t < $$l; t++) {
        var todo = $$obj[t];
todo.isHidden = (t > 4) ? true : false
if (todo.properties) {
if (todo.properties.link) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)) + "\u003E\u003Ca" + (pug.attr("href", todo.properties.link, true, true)+pug.attr("data-target", todo.properties.target, true, true)+pug.attr("data-autoclose", todo.properties.autoclose, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isDisabled ? 'todo-disabled' : ''} ${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)+pug.attr("title", `${todo.isDisabled ? 'This to-do has been deactivated and cannot be updated.' : ''}`, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Cspan" + (pug.attr("class", pug.classes([`todos-status-${todo.status}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if ((renderMode == 'groupByDueDate')) {
pug_html = pug_html + "&nbsp;";
if ((todo.assignedTo.length > 0)) {
// iterate todo.assignedTo
;(function(){
  var $$obj = todo.assignedTo;
  if ('number' == typeof $$obj.length) {
      for (var pug_index5 = 0, $$l = $$obj.length; pug_index5 < $$l; pug_index5++) {
        var assignedToId = $$obj[pug_index5];
pug_html = pug_html + "\u003Cspan" + (pug.attr("class", pug.classes(["ui","mini","label","hidden",`todo-assignedToId-${assignedToId}`], [false,false,false,false,true]), false, true)) + "\u003E\u003C\u002Fspan\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index5 in $$obj) {
      $$l++;
      var assignedToId = $$obj[pug_index5];
pug_html = pug_html + "\u003Cspan" + (pug.attr("class", pug.classes(["ui","mini","label","hidden",`todo-assignedToId-${assignedToId}`], [false,false,false,false,true]), false, true)) + "\u003E\u003C\u002Fspan\u003E";
    }
  }
}).call(this);

}
}
if ((todo.reschedule != 'Never')) {
pug_html = pug_html + "&nbsp;\u003Cspan class=\"ui mini label\"\u003E\u003Ci class=\"sync alternate icon\"\u003E\u003C\u002Fi\u003ERepeated " + (pug.escape(null == (pug_interp = todo.reschedule) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if ((todo.dueAtFormatted && ((bucket == 'overdue') || (bucket == 'complete')))) {
pug_html = pug_html + "\u003Csmall class=\"yellow-strip no-wrap\"\u003E" + (pug.escape(null == (pug_interp = todo.dueAtFormatted) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var t in $$obj) {
      $$l++;
      var todo = $$obj[t];
todo.isHidden = (t > 4) ? true : false
if (todo.properties) {
if (todo.properties.link) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)) + "\u003E\u003Ca" + (pug.attr("href", todo.properties.link, true, true)+pug.attr("data-target", todo.properties.target, true, true)+pug.attr("data-autoclose", todo.properties.autoclose, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isDisabled ? 'todo-disabled' : ''} ${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)+pug.attr("title", `${todo.isDisabled ? 'This to-do has been deactivated and cannot be updated.' : ''}`, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Cspan" + (pug.attr("class", pug.classes([`todos-status-${todo.status}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if ((renderMode == 'groupByDueDate')) {
pug_html = pug_html + "&nbsp;";
if ((todo.assignedTo.length > 0)) {
// iterate todo.assignedTo
;(function(){
  var $$obj = todo.assignedTo;
  if ('number' == typeof $$obj.length) {
      for (var pug_index6 = 0, $$l = $$obj.length; pug_index6 < $$l; pug_index6++) {
        var assignedToId = $$obj[pug_index6];
pug_html = pug_html + "\u003Cspan" + (pug.attr("class", pug.classes(["ui","mini","label","hidden",`todo-assignedToId-${assignedToId}`], [false,false,false,false,true]), false, true)) + "\u003E\u003C\u002Fspan\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index6 in $$obj) {
      $$l++;
      var assignedToId = $$obj[pug_index6];
pug_html = pug_html + "\u003Cspan" + (pug.attr("class", pug.classes(["ui","mini","label","hidden",`todo-assignedToId-${assignedToId}`], [false,false,false,false,true]), false, true)) + "\u003E\u003C\u002Fspan\u003E";
    }
  }
}).call(this);

}
}
if ((todo.reschedule != 'Never')) {
pug_html = pug_html + "&nbsp;\u003Cspan class=\"ui mini label\"\u003E\u003Ci class=\"sync alternate icon\"\u003E\u003C\u002Fi\u003ERepeated " + (pug.escape(null == (pug_interp = todo.reschedule) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if ((todo.dueAtFormatted && ((bucket == 'overdue') || (bucket == 'complete')))) {
pug_html = pug_html + "\u003Csmall class=\"yellow-strip no-wrap\"\u003E" + (pug.escape(null == (pug_interp = todo.dueAtFormatted) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
    }
  }
}).call(this);

if ((todoList.length > 4)) {
pug_html = pug_html + "\u003Cbr\u003E\u003Ca class=\"ui mini right labeled icon basic blue button pull-right\" id=\"todo-showmore-js\"\u003E\u003Ci class=\"down arrow icon\"\u003E\u003C\u002Fi\u003EShow " + (pug.escape(null == (pug_interp = todoList.length - 4) ? "" : pug_interp)) + " more to-dos...\u003C\u002Fa\u003E\u003Ca class=\"ui mini right labeled icon basic blue button hidden pull-right\" id=\"todo-showless-js\"\u003E\u003Ci class=\"up arrow icon\"\u003E\u003C\u002Fi\u003EShow less to-dos...\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

}
else
if ((renderMode == 'groupByPerson')) {
// iterate todos
;(function(){
  var $$obj = todos;
  if ('number' == typeof $$obj.length) {
      for (var personId = 0, $$l = $$obj.length; personId < $$l; personId++) {
        var buckets = $$obj[personId];
pug_html = pug_html + "\u003Ctr class=\"top aligned\" style=\"background:#F7F7F7;\"\u003E\u003Ctd colspan=\"2\"\u003E\u003Ch3" + (pug.attr("class", pug.classes(["no-wrap",`todo-assignedToId-${personId}`], [false,true]), false, true)+" style=\"padding-left: 15px;\"") + "\u003E\u003Cdiv class=\"ui active inline tiny grey loader\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fh3\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
// iterate buckets
;(function(){
  var $$obj = buckets;
  if ('number' == typeof $$obj.length) {
      for (var bucket = 0, $$l = $$obj.length; bucket < $$l; bucket++) {
        var todoList = $$obj[bucket];
pug_html = pug_html + "\u003Ctr class=\"top aligned\"\u003E\u003Ctd\u003E";
if (bucket == 'overdue') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"color: #f2711c; font-weight: 400; padding-left: 15px;\"\u003EOverdue\u003C\u002Fdiv\u003E";
}
else
if (bucket == 'withoutDueDate') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400; padding-left: 15px;\"\u003EWithout due date\u003C\u002Fdiv\u003E";
}
else
if (bucket == 'complete') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400; padding-left: 15px;\"\u003ECompleted\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400; padding-left: 15px;\"\u003E" + (pug.escape(null == (pug_interp = bucket) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd\u003E";
// iterate todoList
;(function(){
  var $$obj = todoList;
  if ('number' == typeof $$obj.length) {
      for (var t = 0, $$l = $$obj.length; t < $$l; t++) {
        var todo = $$obj[t];
todo.isHidden = (t > 4) ? true : false
if (todo.properties) {
if (todo.properties.link) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)) + "\u003E\u003Ca" + (pug.attr("href", todo.properties.link, true, true)+pug.attr("data-target", todo.properties.target, true, true)+pug.attr("data-autoclose", todo.properties.autoclose, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isDisabled ? 'todo-disabled' : ''} ${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)+pug.attr("title", `${todo.isDisabled ? 'This to-do has been deactivated and cannot be updated.' : ''}`, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Cspan" + (pug.attr("class", pug.classes([`todos-status-${todo.status}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if ((todo.reschedule != 'Never')) {
pug_html = pug_html + "&nbsp;\u003Cspan class=\"ui mini label\"\u003E\u003Ci class=\"sync alternate icon\"\u003E\u003C\u002Fi\u003ERepeated " + (pug.escape(null == (pug_interp = todo.reschedule) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if ((todo.dueAtFormatted && ((bucket == 'overdue') || (bucket == 'complete')))) {
pug_html = pug_html + "\u003Csmall class=\"yellow-strip no-wrap\"\u003E" + (pug.escape(null == (pug_interp = todo.dueAtFormatted) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var t in $$obj) {
      $$l++;
      var todo = $$obj[t];
todo.isHidden = (t > 4) ? true : false
if (todo.properties) {
if (todo.properties.link) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)) + "\u003E\u003Ca" + (pug.attr("href", todo.properties.link, true, true)+pug.attr("data-target", todo.properties.target, true, true)+pug.attr("data-autoclose", todo.properties.autoclose, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isDisabled ? 'todo-disabled' : ''} ${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)+pug.attr("title", `${todo.isDisabled ? 'This to-do has been deactivated and cannot be updated.' : ''}`, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Cspan" + (pug.attr("class", pug.classes([`todos-status-${todo.status}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if ((todo.reschedule != 'Never')) {
pug_html = pug_html + "&nbsp;\u003Cspan class=\"ui mini label\"\u003E\u003Ci class=\"sync alternate icon\"\u003E\u003C\u002Fi\u003ERepeated " + (pug.escape(null == (pug_interp = todo.reschedule) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if ((todo.dueAtFormatted && ((bucket == 'overdue') || (bucket == 'complete')))) {
pug_html = pug_html + "\u003Csmall class=\"yellow-strip no-wrap\"\u003E" + (pug.escape(null == (pug_interp = todo.dueAtFormatted) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
    }
  }
}).call(this);

if ((todoList.length > 4)) {
pug_html = pug_html + "\u003Cbr\u003E\u003Ca class=\"ui mini right labeled icon basic blue button pull-right\" id=\"todo-showmore-js\"\u003E\u003Ci class=\"down arrow icon\"\u003E\u003C\u002Fi\u003EShow " + (pug.escape(null == (pug_interp = todoList.length - 4) ? "" : pug_interp)) + " more to-dos...\u003C\u002Fa\u003E\u003Ca class=\"ui mini right labeled icon basic blue button hidden pull-right\" id=\"todo-showless-js\"\u003E\u003Ci class=\"up arrow icon\"\u003E\u003C\u002Fi\u003EShow less to-dos...\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var bucket in $$obj) {
      $$l++;
      var todoList = $$obj[bucket];
pug_html = pug_html + "\u003Ctr class=\"top aligned\"\u003E\u003Ctd\u003E";
if (bucket == 'overdue') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"color: #f2711c; font-weight: 400; padding-left: 15px;\"\u003EOverdue\u003C\u002Fdiv\u003E";
}
else
if (bucket == 'withoutDueDate') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400; padding-left: 15px;\"\u003EWithout due date\u003C\u002Fdiv\u003E";
}
else
if (bucket == 'complete') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400; padding-left: 15px;\"\u003ECompleted\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400; padding-left: 15px;\"\u003E" + (pug.escape(null == (pug_interp = bucket) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd\u003E";
// iterate todoList
;(function(){
  var $$obj = todoList;
  if ('number' == typeof $$obj.length) {
      for (var t = 0, $$l = $$obj.length; t < $$l; t++) {
        var todo = $$obj[t];
todo.isHidden = (t > 4) ? true : false
if (todo.properties) {
if (todo.properties.link) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)) + "\u003E\u003Ca" + (pug.attr("href", todo.properties.link, true, true)+pug.attr("data-target", todo.properties.target, true, true)+pug.attr("data-autoclose", todo.properties.autoclose, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isDisabled ? 'todo-disabled' : ''} ${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)+pug.attr("title", `${todo.isDisabled ? 'This to-do has been deactivated and cannot be updated.' : ''}`, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Cspan" + (pug.attr("class", pug.classes([`todos-status-${todo.status}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if ((todo.reschedule != 'Never')) {
pug_html = pug_html + "&nbsp;\u003Cspan class=\"ui mini label\"\u003E\u003Ci class=\"sync alternate icon\"\u003E\u003C\u002Fi\u003ERepeated " + (pug.escape(null == (pug_interp = todo.reschedule) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if ((todo.dueAtFormatted && ((bucket == 'overdue') || (bucket == 'complete')))) {
pug_html = pug_html + "\u003Csmall class=\"yellow-strip no-wrap\"\u003E" + (pug.escape(null == (pug_interp = todo.dueAtFormatted) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var t in $$obj) {
      $$l++;
      var todo = $$obj[t];
todo.isHidden = (t > 4) ? true : false
if (todo.properties) {
if (todo.properties.link) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)) + "\u003E\u003Ca" + (pug.attr("href", todo.properties.link, true, true)+pug.attr("data-target", todo.properties.target, true, true)+pug.attr("data-autoclose", todo.properties.autoclose, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isDisabled ? 'todo-disabled' : ''} ${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)+pug.attr("title", `${todo.isDisabled ? 'This to-do has been deactivated and cannot be updated.' : ''}`, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Cspan" + (pug.attr("class", pug.classes([`todos-status-${todo.status}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if ((todo.reschedule != 'Never')) {
pug_html = pug_html + "&nbsp;\u003Cspan class=\"ui mini label\"\u003E\u003Ci class=\"sync alternate icon\"\u003E\u003C\u002Fi\u003ERepeated " + (pug.escape(null == (pug_interp = todo.reschedule) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if ((todo.dueAtFormatted && ((bucket == 'overdue') || (bucket == 'complete')))) {
pug_html = pug_html + "\u003Csmall class=\"yellow-strip no-wrap\"\u003E" + (pug.escape(null == (pug_interp = todo.dueAtFormatted) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
    }
  }
}).call(this);

if ((todoList.length > 4)) {
pug_html = pug_html + "\u003Cbr\u003E\u003Ca class=\"ui mini right labeled icon basic blue button pull-right\" id=\"todo-showmore-js\"\u003E\u003Ci class=\"down arrow icon\"\u003E\u003C\u002Fi\u003EShow " + (pug.escape(null == (pug_interp = todoList.length - 4) ? "" : pug_interp)) + " more to-dos...\u003C\u002Fa\u003E\u003Ca class=\"ui mini right labeled icon basic blue button hidden pull-right\" id=\"todo-showless-js\"\u003E\u003Ci class=\"up arrow icon\"\u003E\u003C\u002Fi\u003EShow less to-dos...\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003Ctr class=\"top aligned\"\u003E\u003Ctd colspan=\"2\"\u003E&nbsp;\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var personId in $$obj) {
      $$l++;
      var buckets = $$obj[personId];
pug_html = pug_html + "\u003Ctr class=\"top aligned\" style=\"background:#F7F7F7;\"\u003E\u003Ctd colspan=\"2\"\u003E\u003Ch3" + (pug.attr("class", pug.classes(["no-wrap",`todo-assignedToId-${personId}`], [false,true]), false, true)+" style=\"padding-left: 15px;\"") + "\u003E\u003Cdiv class=\"ui active inline tiny grey loader\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fh3\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
// iterate buckets
;(function(){
  var $$obj = buckets;
  if ('number' == typeof $$obj.length) {
      for (var bucket = 0, $$l = $$obj.length; bucket < $$l; bucket++) {
        var todoList = $$obj[bucket];
pug_html = pug_html + "\u003Ctr class=\"top aligned\"\u003E\u003Ctd\u003E";
if (bucket == 'overdue') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"color: #f2711c; font-weight: 400; padding-left: 15px;\"\u003EOverdue\u003C\u002Fdiv\u003E";
}
else
if (bucket == 'withoutDueDate') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400; padding-left: 15px;\"\u003EWithout due date\u003C\u002Fdiv\u003E";
}
else
if (bucket == 'complete') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400; padding-left: 15px;\"\u003ECompleted\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400; padding-left: 15px;\"\u003E" + (pug.escape(null == (pug_interp = bucket) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd\u003E";
// iterate todoList
;(function(){
  var $$obj = todoList;
  if ('number' == typeof $$obj.length) {
      for (var t = 0, $$l = $$obj.length; t < $$l; t++) {
        var todo = $$obj[t];
todo.isHidden = (t > 4) ? true : false
if (todo.properties) {
if (todo.properties.link) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)) + "\u003E\u003Ca" + (pug.attr("href", todo.properties.link, true, true)+pug.attr("data-target", todo.properties.target, true, true)+pug.attr("data-autoclose", todo.properties.autoclose, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isDisabled ? 'todo-disabled' : ''} ${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)+pug.attr("title", `${todo.isDisabled ? 'This to-do has been deactivated and cannot be updated.' : ''}`, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Cspan" + (pug.attr("class", pug.classes([`todos-status-${todo.status}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if ((todo.reschedule != 'Never')) {
pug_html = pug_html + "&nbsp;\u003Cspan class=\"ui mini label\"\u003E\u003Ci class=\"sync alternate icon\"\u003E\u003C\u002Fi\u003ERepeated " + (pug.escape(null == (pug_interp = todo.reschedule) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if ((todo.dueAtFormatted && ((bucket == 'overdue') || (bucket == 'complete')))) {
pug_html = pug_html + "\u003Csmall class=\"yellow-strip no-wrap\"\u003E" + (pug.escape(null == (pug_interp = todo.dueAtFormatted) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var t in $$obj) {
      $$l++;
      var todo = $$obj[t];
todo.isHidden = (t > 4) ? true : false
if (todo.properties) {
if (todo.properties.link) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)) + "\u003E\u003Ca" + (pug.attr("href", todo.properties.link, true, true)+pug.attr("data-target", todo.properties.target, true, true)+pug.attr("data-autoclose", todo.properties.autoclose, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isDisabled ? 'todo-disabled' : ''} ${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)+pug.attr("title", `${todo.isDisabled ? 'This to-do has been deactivated and cannot be updated.' : ''}`, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Cspan" + (pug.attr("class", pug.classes([`todos-status-${todo.status}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if ((todo.reschedule != 'Never')) {
pug_html = pug_html + "&nbsp;\u003Cspan class=\"ui mini label\"\u003E\u003Ci class=\"sync alternate icon\"\u003E\u003C\u002Fi\u003ERepeated " + (pug.escape(null == (pug_interp = todo.reschedule) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if ((todo.dueAtFormatted && ((bucket == 'overdue') || (bucket == 'complete')))) {
pug_html = pug_html + "\u003Csmall class=\"yellow-strip no-wrap\"\u003E" + (pug.escape(null == (pug_interp = todo.dueAtFormatted) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
    }
  }
}).call(this);

if ((todoList.length > 4)) {
pug_html = pug_html + "\u003Cbr\u003E\u003Ca class=\"ui mini right labeled icon basic blue button pull-right\" id=\"todo-showmore-js\"\u003E\u003Ci class=\"down arrow icon\"\u003E\u003C\u002Fi\u003EShow " + (pug.escape(null == (pug_interp = todoList.length - 4) ? "" : pug_interp)) + " more to-dos...\u003C\u002Fa\u003E\u003Ca class=\"ui mini right labeled icon basic blue button hidden pull-right\" id=\"todo-showless-js\"\u003E\u003Ci class=\"up arrow icon\"\u003E\u003C\u002Fi\u003EShow less to-dos...\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var bucket in $$obj) {
      $$l++;
      var todoList = $$obj[bucket];
pug_html = pug_html + "\u003Ctr class=\"top aligned\"\u003E\u003Ctd\u003E";
if (bucket == 'overdue') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"color: #f2711c; font-weight: 400; padding-left: 15px;\"\u003EOverdue\u003C\u002Fdiv\u003E";
}
else
if (bucket == 'withoutDueDate') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400; padding-left: 15px;\"\u003EWithout due date\u003C\u002Fdiv\u003E";
}
else
if (bucket == 'complete') {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400; padding-left: 15px;\"\u003ECompleted\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"no-wrap\" style=\"font-weight: 400; padding-left: 15px;\"\u003E" + (pug.escape(null == (pug_interp = bucket) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd\u003E";
// iterate todoList
;(function(){
  var $$obj = todoList;
  if ('number' == typeof $$obj.length) {
      for (var t = 0, $$l = $$obj.length; t < $$l; t++) {
        var todo = $$obj[t];
todo.isHidden = (t > 4) ? true : false
if (todo.properties) {
if (todo.properties.link) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)) + "\u003E\u003Ca" + (pug.attr("href", todo.properties.link, true, true)+pug.attr("data-target", todo.properties.target, true, true)+pug.attr("data-autoclose", todo.properties.autoclose, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isDisabled ? 'todo-disabled' : ''} ${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)+pug.attr("title", `${todo.isDisabled ? 'This to-do has been deactivated and cannot be updated.' : ''}`, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Cspan" + (pug.attr("class", pug.classes([`todos-status-${todo.status}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if ((todo.reschedule != 'Never')) {
pug_html = pug_html + "&nbsp;\u003Cspan class=\"ui mini label\"\u003E\u003Ci class=\"sync alternate icon\"\u003E\u003C\u002Fi\u003ERepeated " + (pug.escape(null == (pug_interp = todo.reschedule) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if ((todo.dueAtFormatted && ((bucket == 'overdue') || (bucket == 'complete')))) {
pug_html = pug_html + "\u003Csmall class=\"yellow-strip no-wrap\"\u003E" + (pug.escape(null == (pug_interp = todo.dueAtFormatted) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var t in $$obj) {
      $$l++;
      var todo = $$obj[t];
todo.isHidden = (t > 4) ? true : false
if (todo.properties) {
if (todo.properties.link) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)) + "\u003E\u003Ca" + (pug.attr("href", todo.properties.link, true, true)+pug.attr("data-target", todo.properties.target, true, true)+pug.attr("data-autoclose", todo.properties.autoclose, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["todo-item",`${todo.isDisabled ? 'todo-disabled' : ''} ${todo.isHidden ? 'todo-hidden' : ''}`], [false,true]), false, true)+pug.attr("id", `todo-${todo.id}`, true, true)+pug.attr("data-todo", todo, true, true)+pug.attr("title", `${todo.isDisabled ? 'This to-do has been deactivated and cannot be updated.' : ''}`, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["large","icon",todo.icon], [false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Cspan" + (pug.attr("class", pug.classes([`todos-status-${todo.status}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = todo.label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if ((todo.reschedule != 'Never')) {
pug_html = pug_html + "&nbsp;\u003Cspan class=\"ui mini label\"\u003E\u003Ci class=\"sync alternate icon\"\u003E\u003C\u002Fi\u003ERepeated " + (pug.escape(null == (pug_interp = todo.reschedule) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if ((todo.dueAtFormatted && ((bucket == 'overdue') || (bucket == 'complete')))) {
pug_html = pug_html + "\u003Csmall class=\"yellow-strip no-wrap\"\u003E" + (pug.escape(null == (pug_interp = todo.dueAtFormatted) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
    }
  }
}).call(this);

if ((todoList.length > 4)) {
pug_html = pug_html + "\u003Cbr\u003E\u003Ca class=\"ui mini right labeled icon basic blue button pull-right\" id=\"todo-showmore-js\"\u003E\u003Ci class=\"down arrow icon\"\u003E\u003C\u002Fi\u003EShow " + (pug.escape(null == (pug_interp = todoList.length - 4) ? "" : pug_interp)) + " more to-dos...\u003C\u002Fa\u003E\u003Ca class=\"ui mini right labeled icon basic blue button hidden pull-right\" id=\"todo-showless-js\"\u003E\u003Ci class=\"up arrow icon\"\u003E\u003C\u002Fi\u003EShow less to-dos...\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003Ctr class=\"top aligned\"\u003E\u003Ctd colspan=\"2\"\u003E&nbsp;\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

}
if (((renderMode == 'groupByPerson') || (renderMode == 'groupByDueDate')) && (todosMetadata.group !== undefined) && (todosMetadata.group.showLinkToAll)) {
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd class=\"right aligned\" colspan=\"2\"\u003E\u003Ca" + (pug.attr("href", `${groupId}/todos/all`, true, true)) + "\u003EView all to-dos &rarr;\u003C\u002Fa\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
}
pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"content text-center\"\u003E\u003Cspan class=\"yellow-strip\"\u003E\u003Cstrong\u003EHappy days! \u003C\u002Fstrong\u003E\u003Cem\u003EThere are no to-dos...\u003C\u002Fem\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"todos-popup ui popup\" style=\"width:310px;\"\u003E\u003Cdiv class=\"ui active centered inline small grey loader\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"groupId" in locals_for_with?locals_for_with.groupId:typeof groupId!=="undefined"?groupId:undefined,"hasTodos" in locals_for_with?locals_for_with.hasTodos:typeof hasTodos!=="undefined"?hasTodos:undefined,"loading" in locals_for_with?locals_for_with.loading:typeof loading!=="undefined"?loading:undefined,"renderMode" in locals_for_with?locals_for_with.renderMode:typeof renderMode!=="undefined"?renderMode:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined,"todos" in locals_for_with?locals_for_with.todos:typeof todos!=="undefined"?todos:undefined,"todosMetadata" in locals_for_with?locals_for_with.todosMetadata:typeof todosMetadata!=="undefined"?todosMetadata:undefined));;return pug_html;};
module.exports = template;