var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (activities, component) {if (!(activities.length > 0)) {
pug_html = pug_html + "\u003Cdiv class=\"content text-center\"\u003E\u003Cspan class=\"yellow-strip\"\u003ENo information is available...\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"content\"\u003E\u003Ctable class=\"ui striped selectable very basic compact table dataTable\" width=\"100%\" cellspacing=\"0\"\u003E\u003Cthead\u003E\u003Cth class=\"select-checkbox\" style=\"width: 10px;\"\u003E\u003C\u002Fth\u003E";
// iterate component.columns
;(function(){
  var $$obj = component.columns;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var column = $$obj[pug_index0];
pug_html = pug_html + "\u003Cth\u003E" + (pug.escape(null == (pug_interp = column.label) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var column = $$obj[pug_index0];
pug_html = pug_html + "\u003Cth\u003E" + (pug.escape(null == (pug_interp = column.label) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003Cth\u003E\u003C\u002Fth\u003E\u003C\u002Fthead\u003E\u003Ctbody\u003E";
// iterate activities
;(function(){
  var $$obj = activities;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var record = $$obj[pug_index1];
pug_html = pug_html + "\u003Ctr" + (pug.attr("id", record.processId, true, true)) + "\u003E\u003Ctd class=\"select-checkbox\"\u003E\u003C\u002Ftd\u003E";
// iterate record.fields
;(function(){
  var $$obj = record.fields;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var field = $$obj[pug_index2];
pug_html = pug_html + "\u003Ctd\u003E" + (null == (pug_interp = field) ? "" : pug_interp) + "\u003C\u002Ftd\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var field = $$obj[pug_index2];
pug_html = pug_html + "\u003Ctd\u003E" + (null == (pug_interp = field) ? "" : pug_interp) + "\u003C\u002Ftd\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003Ctd\u003E" + (null == (pug_interp = record.actions) ? "" : pug_interp) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var record = $$obj[pug_index1];
pug_html = pug_html + "\u003Ctr" + (pug.attr("id", record.processId, true, true)) + "\u003E\u003Ctd class=\"select-checkbox\"\u003E\u003C\u002Ftd\u003E";
// iterate record.fields
;(function(){
  var $$obj = record.fields;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var field = $$obj[pug_index3];
pug_html = pug_html + "\u003Ctd\u003E" + (null == (pug_interp = field) ? "" : pug_interp) + "\u003C\u002Ftd\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var field = $$obj[pug_index3];
pug_html = pug_html + "\u003Ctd\u003E" + (null == (pug_interp = field) ? "" : pug_interp) + "\u003C\u002Ftd\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003Ctd\u003E" + (null == (pug_interp = record.actions) ? "" : pug_interp) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E";
}}.call(this,"activities" in locals_for_with?locals_for_with.activities:typeof activities!=="undefined"?activities:undefined,"component" in locals_for_with?locals_for_with.component:typeof component!=="undefined"?component:undefined));;return pug_html;};
module.exports = template;