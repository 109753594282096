var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (email, fullName, gravatarHtml, groupId, groupLevel, groupName, isApplication, isLoading, isMaker, isMakerAccount, isOwner, isUserAdmin, menu) {pug_html = pug_html + "\u003Cdiv class=\"ui fixed borderless small menu\" id=\"navigation-menu\"\u003E\u003Ca class=\"header item\" href=\"#\" title=\"Dashboard\"\u003E\u003Cimg" + (" class=\"logo\""+pug.attr("src", require('../images/kotive-logo-square.png'), true, true)) + "\u003E\u003C\u002Fa\u003E";
if (isLoading) {
pug_html = pug_html + "\u003Cdiv class=\"text-center\"\u003E\u003Cimg" + (pug.attr("src", require('../images/loading.gif'), true, true)+" alt=\"\" style=\"margin:12px 0px 0px 12px;\" width=\"25\" height=\"25\"") + "\u003E\u003C\u002Fdiv\u003E";
}
else {
if (isMaker && isApplication) {
pug_html = pug_html + "\u003Ca class=\"item\" href=\"\u002Fdesign\u002F\"\u003EDesigner\u003C\u002Fa\u003E";
}
// iterate menu
;(function(){
  var $$obj = menu;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var item = $$obj[pug_index0];
if (item.sub) {
var isScrolling = "no"
if ((item.sub.length > 16)) {
var isScrolling = "scrolling"
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["ui","simple","dropdown","item",isScrolling], [false,false,false,false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.title) ? "" : pug_interp)) + "\u003Ci class=\"dropdown icon\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"menu\"\u003E";
// iterate item.sub
;(function(){
  var $$obj = item.sub;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var itemSub = $$obj[pug_index1];
if (itemSub.sub) {
pug_html = pug_html + "\u003Cdiv class=\"item\"\u003E\u003Ci class=\"dropdown icon\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003Cdiv class=\"menu\"\u003E";
// iterate itemSub.sub
;(function(){
  var $$obj = itemSub.sub;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var itemSubsub = $$obj[pug_index2];
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", `${itemSubsub.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var itemSubsub = $$obj[pug_index2];
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", `${itemSubsub.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", `${itemSub.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var itemSub = $$obj[pug_index1];
if (itemSub.sub) {
pug_html = pug_html + "\u003Cdiv class=\"item\"\u003E\u003Ci class=\"dropdown icon\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003Cdiv class=\"menu\"\u003E";
// iterate itemSub.sub
;(function(){
  var $$obj = itemSub.sub;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var itemSubsub = $$obj[pug_index3];
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", `${itemSubsub.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var itemSubsub = $$obj[pug_index3];
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", `${itemSubsub.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", `${itemSub.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", `${item.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var item = $$obj[pug_index0];
if (item.sub) {
var isScrolling = "no"
if ((item.sub.length > 16)) {
var isScrolling = "scrolling"
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["ui","simple","dropdown","item",isScrolling], [false,false,false,false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.title) ? "" : pug_interp)) + "\u003Ci class=\"dropdown icon\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"menu\"\u003E";
// iterate item.sub
;(function(){
  var $$obj = item.sub;
  if ('number' == typeof $$obj.length) {
      for (var pug_index4 = 0, $$l = $$obj.length; pug_index4 < $$l; pug_index4++) {
        var itemSub = $$obj[pug_index4];
if (itemSub.sub) {
pug_html = pug_html + "\u003Cdiv class=\"item\"\u003E\u003Ci class=\"dropdown icon\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003Cdiv class=\"menu\"\u003E";
// iterate itemSub.sub
;(function(){
  var $$obj = itemSub.sub;
  if ('number' == typeof $$obj.length) {
      for (var pug_index5 = 0, $$l = $$obj.length; pug_index5 < $$l; pug_index5++) {
        var itemSubsub = $$obj[pug_index5];
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", `${itemSubsub.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index5 in $$obj) {
      $$l++;
      var itemSubsub = $$obj[pug_index5];
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", `${itemSubsub.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", `${itemSub.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index4 in $$obj) {
      $$l++;
      var itemSub = $$obj[pug_index4];
if (itemSub.sub) {
pug_html = pug_html + "\u003Cdiv class=\"item\"\u003E\u003Ci class=\"dropdown icon\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003Cdiv class=\"menu\"\u003E";
// iterate itemSub.sub
;(function(){
  var $$obj = itemSub.sub;
  if ('number' == typeof $$obj.length) {
      for (var pug_index6 = 0, $$l = $$obj.length; pug_index6 < $$l; pug_index6++) {
        var itemSubsub = $$obj[pug_index6];
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", `${itemSubsub.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index6 in $$obj) {
      $$l++;
      var itemSubsub = $$obj[pug_index6];
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", `${itemSubsub.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", `${itemSub.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = itemSub.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", `${item.url}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
    }
  }
}).call(this);

if (isUserAdmin) {
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", `/hub/${groupId}/people/`, true, true)) + "\u003EPeople\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"right menu\"\u003E\u003Ca class=\"item\" href=\"\u002Fhub\u002Fswitch\"\u003E\u003Cimg" + (" class=\"rounded\""+pug.attr("src", require(`../images/people-groups-${groupLevel}.png`), true, true)) + "\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = groupName) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Cdiv class=\"ui simple dropdown item\" style=\"padding: 0.3em 0.7em\"\u003E" + (null == (pug_interp = gravatarHtml) ? "" : pug_interp) + "\u003Ci class=\"dropdown icon\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"menu\"\u003E\u003Cdiv class=\"header\"\u003ELogged in as " + (pug.escape(null == (pug_interp = fullName) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"item\""+pug.attr("href", `/hub/${groupId}/person/me/`, true, true)) + "\u003E" + (null == (pug_interp = gravatarHtml) ? "" : pug_interp) + "My profile\u003C\u002Fa\u003E\u003Ca class=\"item announcementsJs\" href=\"#\" title=\"Product announcements\"\u003E\u003Ci class=\"rss circular inverted icon green\"\u003E\u003C\u002Fi\u003EProduct announcements\u003C\u002Fa\u003E\u003Ca class=\"item\" href=\"https:\u002F\u002Fwww.kotive.com\u002Fhow-to\u002F\" target=\"_blank\" title=\"Get help on how to use Kotive\"\u003E\u003Ci class=\"book circular inverted icon green\"\u003E\u003C\u002Fi\u003ESupport articles\u003C\u002Fa\u003E\u003Ca class=\"item\" href=\"mailto:support@kotive.com\"\u003E\u003Ci class=\"envelope circular inverted icon green\"\u003E\u003C\u002Fi\u003EContact us\u003C\u002Fa\u003E";
if (isOwner && isMakerAccount) {
pug_html = pug_html + "\u003Ca" + (" class=\"item\""+pug.attr("href", `/billing/verify/${groupId}`, true, true)) + "\u003E\u003Ci class=\"star icon\"\u003E\u003C\u002Fi\u003EUpgrade your account\u003C\u002Fa\u003E\u003Ca" + (" class=\"item\""+pug.attr("href", `https://webapp.kotive.com/hub/taskflow/new/190/ab9aad26229d4dfbb1e07da24c968bb7/full?_49622=${fullName}&_49623=${email}&_49632=${groupId}`, true, true)+" target=\"_blank\"") + "\u003E\u003Ci class=\"window close icon\"\u003E\u003C\u002Fi\u003EClose your account\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003Ca class=\"item\" href=\"\u002Fhub\u002Flogout\"\u003E\u003Ci class=\"power orange circular inverted icon\"\u003E\u003C\u002Fi\u003ELogout\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"email" in locals_for_with?locals_for_with.email:typeof email!=="undefined"?email:undefined,"fullName" in locals_for_with?locals_for_with.fullName:typeof fullName!=="undefined"?fullName:undefined,"gravatarHtml" in locals_for_with?locals_for_with.gravatarHtml:typeof gravatarHtml!=="undefined"?gravatarHtml:undefined,"groupId" in locals_for_with?locals_for_with.groupId:typeof groupId!=="undefined"?groupId:undefined,"groupLevel" in locals_for_with?locals_for_with.groupLevel:typeof groupLevel!=="undefined"?groupLevel:undefined,"groupName" in locals_for_with?locals_for_with.groupName:typeof groupName!=="undefined"?groupName:undefined,"isApplication" in locals_for_with?locals_for_with.isApplication:typeof isApplication!=="undefined"?isApplication:undefined,"isLoading" in locals_for_with?locals_for_with.isLoading:typeof isLoading!=="undefined"?isLoading:undefined,"isMaker" in locals_for_with?locals_for_with.isMaker:typeof isMaker!=="undefined"?isMaker:undefined,"isMakerAccount" in locals_for_with?locals_for_with.isMakerAccount:typeof isMakerAccount!=="undefined"?isMakerAccount:undefined,"isOwner" in locals_for_with?locals_for_with.isOwner:typeof isOwner!=="undefined"?isOwner:undefined,"isUserAdmin" in locals_for_with?locals_for_with.isUserAdmin:typeof isUserAdmin!=="undefined"?isUserAdmin:undefined,"menu" in locals_for_with?locals_for_with.menu:typeof menu!=="undefined"?menu:undefined));;return pug_html;};
module.exports = template;