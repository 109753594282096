var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (baseGroupId, currentPerson, gravatar, groups, people) {pug_html = pug_html + "\u003Cdiv class=\"ui breadcrumb\" id=\"breadcrumb\"\u003E\u003Cdiv class=\"section\"\u003EPeople, organizations &amp; teams\u003C\u002Fdiv\u003E\u003Cdiv class=\"divider\"\u003E\u002F\u003C\u002Fdiv\u003E";
// iterate groups
;(function(){
  var $$obj = groups;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var group = $$obj[pug_index0];
pug_html = pug_html + "\u003Ca" + (" class=\"section\""+pug.attr("href", `${baseGroupId}/people/${group.id}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = group.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Cdiv class=\"divider\"\u003E\u002F\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var group = $$obj[pug_index0];
pug_html = pug_html + "\u003Ca" + (" class=\"section\""+pug.attr("href", `${baseGroupId}/people/${group.id}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = group.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Cdiv class=\"divider\"\u003E\u002F\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003Cdiv class=\"section\"\u003E\u003Cdiv class=\"ui inline floating dropdown\"\u003E\u003Cdiv class=\"text\"\u003E" + (pug.escape(null == (pug_interp = currentPerson.displayName) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Ci class=\"icon dropdown\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"menu\"\u003E\u003Cdiv class=\"ui icon search input\"\u003E\u003Ci class=\"search icon\"\u003E\u003C\u002Fi\u003E\u003Cinput placeholder=\"Find a person...\" type=\"text\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"scrolling menu\"\u003E";
// iterate people
;(function(){
  var $$obj = people;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var person = $$obj[pug_index1];
var avatar = gravatar.generateUrl(person.email, 80)
if ((person.id == currentPerson.id)) {
pug_html = pug_html + "\u003Cdiv" + (" class=\"item active\""+pug.attr("data-value", person.id, true, true)) + "\u003E\u003Cimg" + (" class=\"ui mini avatar image\""+pug.attr("src", avatar, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = `${person.firstname} ${person.lastname}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv" + (" class=\"item\""+pug.attr("data-value", person.id, true, true)) + "\u003E\u003Cimg" + (" class=\"ui mini avatar image\""+pug.attr("src", avatar, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = `${person.firstname} ${person.lastname}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var person = $$obj[pug_index1];
var avatar = gravatar.generateUrl(person.email, 80)
if ((person.id == currentPerson.id)) {
pug_html = pug_html + "\u003Cdiv" + (" class=\"item active\""+pug.attr("data-value", person.id, true, true)) + "\u003E\u003Cimg" + (" class=\"ui mini avatar image\""+pug.attr("src", avatar, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = `${person.firstname} ${person.lastname}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv" + (" class=\"item\""+pug.attr("data-value", person.id, true, true)) + "\u003E\u003Cimg" + (" class=\"ui mini avatar image\""+pug.attr("src", avatar, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = `${person.firstname} ${person.lastname}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"baseGroupId" in locals_for_with?locals_for_with.baseGroupId:typeof baseGroupId!=="undefined"?baseGroupId:undefined,"currentPerson" in locals_for_with?locals_for_with.currentPerson:typeof currentPerson!=="undefined"?currentPerson:undefined,"gravatar" in locals_for_with?locals_for_with.gravatar:typeof gravatar!=="undefined"?gravatar:undefined,"groups" in locals_for_with?locals_for_with.groups:typeof groups!=="undefined"?groups:undefined,"people" in locals_for_with?locals_for_with.people:typeof people!=="undefined"?people:undefined));;return pug_html;};
module.exports = template;