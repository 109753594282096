var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (contentMaxHeight, isLoading, kanbanBoard, kanbanData) {pug_html = pug_html + "\u003Cdiv id=\"workarea\"\u003E\u003Cdiv class=\"ui grid\"\u003E\u003Cdiv class=\"column\"\u003E\u003Cdiv class=\"pull-left\"\u003E\u003Ch2 id=\"page-title-js\"\u003E";
if (kanbanBoard) {
pug_html = pug_html + (pug.escape(null == (pug_interp = kanbanBoard.label) ? "" : pug_interp));
}
pug_html = pug_html + "\u003C\u002Fh2\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"pull-right\" style=\"display: inline-flex;\"\u003E\u003Cdiv id=\"switch-view-js\"\u003E\u003C\u002Fdiv\u003E" + (null == (pug_interp = require("./add-new-records.pug").call(this, locals)) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"clearfix\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"ui grid\"\u003E\u003Cdiv class=\"column\"\u003E\u003Cdiv class=\"pull-left\"\u003E\u003Cdiv id=\"daterange_filter\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"pull-right\"\u003E" + (null == (pug_interp = require("./quickfilter.pug").call(this, locals)) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"clearfix\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (kanbanBoard) {
pug_html = pug_html + "\u003Cdiv class=\"kanban-stages\"\u003E";
if (kanbanBoard.stages) {
// iterate kanbanBoard.stages
;(function(){
  var $$obj = kanbanBoard.stages;
  if ('number' == typeof $$obj.length) {
      for (var s = 0, $$l = $$obj.length; s < $$l; s++) {
        var stage = $$obj[s];
pug_html = pug_html + "\u003Cdiv" + (" class=\"kanban-stage\""+pug.attr("id", `kanban-${stage.key}`, true, true)) + "\u003E\u003Cdiv class=\"kanban-stage-header\"\u003E\u003Cdiv class=\"pull-left\"\u003E" + (pug.escape(null == (pug_interp = stage.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"pull-right\"\u003E\u003Cdiv class=\"kanban-counter ui circular label\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv" + (" class=\"kanban-stage-content\""+pug.attr("style", pug.style(`height: ${contentMaxHeight}px;`), true, true)) + "\u003E";
if (!(isLoading)) {
if (kanbanData[s].length > 0) {
// iterate kanbanData[s]
;(function(){
  var $$obj = kanbanData[s];
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var card = $$obj[pug_index1];
pug_html = pug_html + (null == (pug_interp = card) ? "" : pug_interp);
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var card = $$obj[pug_index1];
pug_html = pug_html + (null == (pug_interp = card) ? "" : pug_interp);
    }
  }
}).call(this);

}
}
else {
pug_html = pug_html + "\u003Cdiv class=\"text-center\"\u003E\u003Cimg" + (" class=\"ui centered image\""+pug.attr("src", require('../../images/loading.gif'), true, true)+" alt=\"\"") + "\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"kanban-stage-footer\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var s in $$obj) {
      $$l++;
      var stage = $$obj[s];
pug_html = pug_html + "\u003Cdiv" + (" class=\"kanban-stage\""+pug.attr("id", `kanban-${stage.key}`, true, true)) + "\u003E\u003Cdiv class=\"kanban-stage-header\"\u003E\u003Cdiv class=\"pull-left\"\u003E" + (pug.escape(null == (pug_interp = stage.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"pull-right\"\u003E\u003Cdiv class=\"kanban-counter ui circular label\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv" + (" class=\"kanban-stage-content\""+pug.attr("style", pug.style(`height: ${contentMaxHeight}px;`), true, true)) + "\u003E";
if (!(isLoading)) {
if (kanbanData[s].length > 0) {
// iterate kanbanData[s]
;(function(){
  var $$obj = kanbanData[s];
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var card = $$obj[pug_index2];
pug_html = pug_html + (null == (pug_interp = card) ? "" : pug_interp);
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var card = $$obj[pug_index2];
pug_html = pug_html + (null == (pug_interp = card) ? "" : pug_interp);
    }
  }
}).call(this);

}
}
else {
pug_html = pug_html + "\u003Cdiv class=\"text-center\"\u003E\u003Cimg" + (" class=\"ui centered image\""+pug.attr("src", require('../../images/loading.gif'), true, true)+" alt=\"\"") + "\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"kanban-stage-footer\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"ui grid\"\u003E\u003Cdiv class=\"column\"\u003E\u003Cimg" + (" class=\"ui centered image\""+pug.attr("src", require('../../images/loading.gif'), true, true)+" alt=\"\"") + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"contentMaxHeight" in locals_for_with?locals_for_with.contentMaxHeight:typeof contentMaxHeight!=="undefined"?contentMaxHeight:undefined,"isLoading" in locals_for_with?locals_for_with.isLoading:typeof isLoading!=="undefined"?isLoading:undefined,"kanbanBoard" in locals_for_with?locals_for_with.kanbanBoard:typeof kanbanBoard!=="undefined"?kanbanBoard:undefined,"kanbanData" in locals_for_with?locals_for_with.kanbanData:typeof kanbanData!=="undefined"?kanbanData:undefined));;return pug_html;};
module.exports = template;