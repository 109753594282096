var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (id, label, profile) {pug_html = pug_html + "\u003Cdiv" + (" class=\"eight wide column\""+pug.attr("id", `${id}`, true, true)) + "\u003E\u003Cdiv class=\"ui fluid raised card\"\u003E\u003Cdiv class=\"content\"\u003E\u003Cdiv class=\"pull-left\"\u003E\u003Ch3\u003E\u003Cimg" + (" class=\"ui circular image\""+pug.attr("src", profile.avatar, true, true)) + "\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"pull-right\"\u003E\u003Ca class=\"person-update-js\" data-target=\"modal-person-update\"\u003E\u003Ci class=\"write icon\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"content\"\u003E\u003Ctable class=\"ui very basic compact unstackable table keyval\"\u003E\u003Ctbody\u003E\u003Ctr\u003E\u003Ctd\u003EFullname\u003C\u002Ftd\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = profile.label) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
if (profile.full.email) {
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003EEmail\u003C\u002Ftd\u003E\u003Ctd\u003E\u003Ci class=\"icon envelope\"\u003E\u003C\u002Fi\u003E\u003Ca" + (pug.attr("href", `mailto:${profile.full.email}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = profile.full.email) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
}
if (profile.full.mobile) {
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003EMobile\u003C\u002Ftd\u003E\u003Ctd\u003E\u003Ci class=\"icon mobile alternate\"\u003E\u003C\u002Fi\u003E\u003Ca" + (pug.attr("href", `tel:${profile.full.mobile}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = profile.full.mobile) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
}
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003ERoles\u003C\u002Ftd\u003E\u003Ctd\u003E";
if (profile.tags) {
// iterate profile.tags
;(function(){
  var $$obj = profile.tags;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var role = $$obj[pug_index0];
if ((role)) {
pug_html = pug_html + "\u003Cdiv class=\"ui small label\"\u003E" + (pug.escape(null == (pug_interp = role) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var role = $$obj[pug_index0];
if ((role)) {
pug_html = pug_html + "\u003Cdiv class=\"ui small label\"\u003E" + (pug.escape(null == (pug_interp = role) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
    }
  }
}).call(this);

}
else {
pug_html = pug_html + "-";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"id" in locals_for_with?locals_for_with.id:typeof id!=="undefined"?id:undefined,"label" in locals_for_with?locals_for_with.label:typeof label!=="undefined"?label:undefined,"profile" in locals_for_with?locals_for_with.profile:typeof profile!=="undefined"?profile:undefined));;return pug_html;};
module.exports = template;