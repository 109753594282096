var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (assessmentId, groupId, permissions, showExtendedInsights, statementTaskflowId, taskflowId) {pug_html = pug_html + "\u003Cdiv id=\"workarea\"\u003E\u003Cdiv class=\"ui grid\"\u003E\u003Cdiv class=\"column\"\u003E\u003Cdiv class=\"ui segments\"\u003E\u003Cdiv class=\"ui segment\"\u003E\u003Cdiv class=\"pull-left\"\u003E\u003Ch2 id=\"page-title-js\"\u003E\u003C\u002Fh2\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"pull-right\"\u003E";
if (permissions.start) {
pug_html = pug_html + "\u003Cdiv class=\"ui buttons\"\u003E\u003Cbutton" + (" class=\"ui green button\""+" id=\"add-statement-js\" data-target=\"modal-taskflow\""+pug.attr("data-url", `/hub/taskflow/start/${groupId}/${statementTaskflowId}/modal`, true, true)+pug.attr("data-referrer", `/${groupId}/risk/${taskflowId}/${assessmentId}`, true, true)) + "\u003E\u003Ci class=\"plus icon\"\u003E\u003C\u002Fi\u003EAdd a risk factor\u002Fstatement\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"clearfix\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"ui segment\"\u003E\u003Cdiv id=\"risk-insights\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"ui segment\"\u003E\u003Ctable class=\"ui striped selectable table risk-assessment\" id=\"datatable\" width=\"100%\" cellspacing=\"0\"\u003E\u003Cimg" + (" class=\"ui centered image\""+pug.attr("src", require('../../images/loading.gif'), true, true)+" alt=\"\"") + "\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E";
if (showExtendedInsights) {
pug_html = pug_html + "\u003Cdiv class=\"ui segment\" style=\"break-before: page;\"\u003E\u003Cdiv id=\"risk-todos\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"assessmentId" in locals_for_with?locals_for_with.assessmentId:typeof assessmentId!=="undefined"?assessmentId:undefined,"groupId" in locals_for_with?locals_for_with.groupId:typeof groupId!=="undefined"?groupId:undefined,"permissions" in locals_for_with?locals_for_with.permissions:typeof permissions!=="undefined"?permissions:undefined,"showExtendedInsights" in locals_for_with?locals_for_with.showExtendedInsights:typeof showExtendedInsights!=="undefined"?showExtendedInsights:undefined,"statementTaskflowId" in locals_for_with?locals_for_with.statementTaskflowId:typeof statementTaskflowId!=="undefined"?statementTaskflowId:undefined,"taskflowId" in locals_for_with?locals_for_with.taskflowId:typeof taskflowId!=="undefined"?taskflowId:undefined));;return pug_html;};
module.exports = template;