var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (component, html, id) {if (!(component)) {
pug_html = pug_html + "\u003Cdiv" + (" class=\"eight wide column\""+pug.attr("id", `widget-${id}`, true, true)) + "\u003E\u003Cdiv class=\"ui fluid raised card\"\u003E\u003Cdiv class=\"content text-center\"\u003E\u003Cdiv class=\"ui active centered inline small grey loader\"\u003E\u003C\u002Fdiv\u003E\u003Csmall\u003E\u003Ccenter\u003EAnalyzing information...\u003C\u002Fcenter\u003E\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv" + (" class=\"eight wide column\""+pug.attr("id", `widget-${id}`, true, true)+pug.attr("data-url", `${component.url}`, true, true)+pug.attr("data-component", component, true, true)) + "\u003E\u003Cdiv class=\"ui fluid raised card\"\u003E";
if (component.overrideContent) {
if (!(html.length > 0)) {
pug_html = pug_html + "\u003Cspan class=\"yellow-strip\"\u003ENot enough information is available...\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + (null == (pug_interp = html) ? "" : pug_interp);
}
}
else {
pug_html = pug_html + "\u003Cdiv class=\"content\"\u003E\u003Cdiv class=\"pull-left\"\u003E\u003Ch3\u003E" + (pug.escape(null == (pug_interp = component.label) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"pull-right\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"content text-left\"\u003E";
if (!(html.length > 0)) {
pug_html = pug_html + "\u003Cspan class=\"yellow-strip\"\u003ENot enough information is available...\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + (null == (pug_interp = html) ? "" : pug_interp);
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}}.call(this,"component" in locals_for_with?locals_for_with.component:typeof component!=="undefined"?component:undefined,"html" in locals_for_with?locals_for_with.html:typeof html!=="undefined"?html:undefined,"id" in locals_for_with?locals_for_with.id:typeof id!=="undefined"?id:undefined));;return pug_html;};
module.exports = template;