var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (uploads) {pug_html = pug_html + "\u003Cdiv class=\"fileuploads-list\"\u003E";
// iterate uploads.reverse()
;(function(){
  var $$obj = uploads.reverse();
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var upload = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"fileuploads-item clearfix\"\u003E\u003Ca" + (pug.attr("href", `https://s3.amazonaws.com/${upload.container}/${upload.key.replace(/\+/g, '%2B').replace(/%20/g, '+')}`, true, true)+" target=\"_blank\"") + "\u003E\u003Cimg class=\"pull-left\" src=\"\" width=\"64\"\u003E" + (pug.escape(null == (pug_interp = upload.filename) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var upload = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"fileuploads-item clearfix\"\u003E\u003Ca" + (pug.attr("href", `https://s3.amazonaws.com/${upload.container}/${upload.key.replace(/\+/g, '%2B').replace(/%20/g, '+')}`, true, true)+" target=\"_blank\"") + "\u003E\u003Cimg class=\"pull-left\" src=\"\" width=\"64\"\u003E" + (pug.escape(null == (pug_interp = upload.filename) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"uploads" in locals_for_with?locals_for_with.uploads:typeof uploads!=="undefined"?uploads:undefined));;return pug_html;};
module.exports = template;