'use strict'

const hubpubsub   = require('./../../utils/hubpubsub.es6')

export const send = (url, method, data, opts)=> {
  const csfrToken = (document.querySelector('meta[name="csrf-token"]') !== null) ? document.querySelector('meta[name="csrf-token"]').content : ''
  return $.ajax({
    url:            url,
    method:         (method !== undefined) ? method : 'GET',
    data:           (data !== undefined) || (data === false) ? JSON.stringify(data) : undefined,
    contentType:    (opts !== undefined) && (opts.contentType !== undefined) ? opts.contentType : 'application/json; charset=utf-8',  // payload
    dataType:       (opts !== undefined) && (opts.dataType !== undefined) ? opts.dataType : 'json',  // response data
    beforeSend:     (xhr)=> {
      xhr.setRequestHeader('Authorization', 'Basic ' + btoa(localStorage.getItem('user-email')+':'+localStorage.getItem('auth-token'))),
      xhr.setRequestHeader('x-csrf-token', csfrToken)
    }
  }).fail( (jqxhr, textStatus, errorThrown)=> {
    const error = `Cannot process request [${jqxhr.status}] (${textStatus}): ${errorThrown}. ${jqxhr.responseText}`
    console.error(error)

    if (jqxhr.status === 401) {
      hubpubsub.publish('notification.show', { kind: 'negative', message: `You need to login again...`})
      setTimeout( ()=> {
        indexedDB.deleteDatabase('KotiveDB')
        localStorage.clear()
        window.location.href = '/hub/login'
      }, 3000)
    } else if (jqxhr.status === 402) {
      hubpubsub.publish('notification.show', { kind: 'negative', message: `You've reached your subscription plan's limit. ${jqxhr.responseText}`})
    } else if (jqxhr.status === 403) {
      hubpubsub.publish('notification.show', { kind: 'negative', message: `You do not have permission to access this. Logout and back in to reset your access permissions.`})
    } else if (jqxhr.status === 405) {
      if ((jqxhr.responseText !== undefined) && (jqxhr.responseText !== '')) {
        hubpubsub.publish('notification.show', { kind: 'negative', message: jqxhr.responseText})
      } else {
        hubpubsub.publish('notification.show', { kind: 'negative', message: `You do not have permission to access this. Logout and back in to reset your access permissions.`})
      }
    } else {
      hubpubsub.publish('notification.show', { kind: 'negative', message: `Sorry, something went wrong... Please refresh your browser and try again.`})
    }
  })
}