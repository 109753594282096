var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (filter, groupId, list, listGroupId, parentListGroupId, segment) {pug_html = pug_html + "\u003Cdiv class=\"panel-filter\" id=\"filterjs\"\u003E\u003Cdiv class=\"ui breadcrumb\"\u003E";
if ((listGroupId !== parentListGroupId)) {
pug_html = pug_html + "\u003Ca" + (" class=\"section\""+pug.attr("href", `/hub/${groupId}_${parentListGroupId}/profiles/${list}/${segment}`, true, true)) + "\u003E\u003Ci class=\"chevron left icon\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E";
}
if (filter) {
pug_html = pug_html + ("\u003Cdiv class=\"section\"\u003E\u003Cdiv class=\"ui dropdown\"\u003E\u003Cspan class=\"text\" id=\"filterjs-label\"\u003E" + (pug.escape(null == (pug_interp = filter.label) ? "" : pug_interp)));
if (filter.count) {
pug_html = pug_html + "\u003Cdiv class=\"ui circular green small label\" id=\"filterjs-count\"\u003E" + (pug.escape(null == (pug_interp = filter.count) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fspan\u003E";
if (filter.menu) {
pug_html = pug_html + "\u003Ci class=\"dropdown icon\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"menu\"\u003E";
// iterate filter.menu
;(function(){
  var $$obj = filter.menu;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var item = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"item\"\u003E" + (pug.escape(null == (pug_interp = item) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var item = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"item\"\u003E" + (pug.escape(null == (pug_interp = item) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"filter" in locals_for_with?locals_for_with.filter:typeof filter!=="undefined"?filter:undefined,"groupId" in locals_for_with?locals_for_with.groupId:typeof groupId!=="undefined"?groupId:undefined,"list" in locals_for_with?locals_for_with.list:typeof list!=="undefined"?list:undefined,"listGroupId" in locals_for_with?locals_for_with.listGroupId:typeof listGroupId!=="undefined"?listGroupId:undefined,"parentListGroupId" in locals_for_with?locals_for_with.parentListGroupId:typeof parentListGroupId!=="undefined"?parentListGroupId:undefined,"segment" in locals_for_with?locals_for_with.segment:typeof segment!=="undefined"?segment:undefined));;return pug_html;};
module.exports = template;