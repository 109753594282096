var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (selectedColumns, tasksWithFields) {pug_html = pug_html + "\u003Ci class=\"close close-popup icon\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"header\"\u003ECustomize the table's columns\u003C\u002Fdiv\u003E\u003Cdiv class=\"description\"\u003EAdd or remove columns from the table\u003Cdiv class=\"ui fluid multiple search selection dropdown\" id=\"column-chooser-dropdown-js\"\u003E\u003Cinput" + (" name=\"columns\" type=\"hidden\""+pug.attr("value", `${selectedColumns}`, true, true)) + "\u003E\u003Ci class=\"dropdown icon\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"default text\"\u003EChoose columns...\u003C\u002Fdiv\u003E\u003Cdiv class=\"menu\"\u003E";
// iterate tasksWithFields
;(function(){
  var $$obj = tasksWithFields;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var task = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"header\"\u003E\u003Cimg" + (" class=\"ui avatar image\""+pug.attr("src", `../images/icons/${task.icon}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = task.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
// iterate task.fields
;(function(){
  var $$obj = task.fields;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var field = $$obj[pug_index1];
if (field.label) {
pug_html = pug_html + "\u003Cdiv" + (" class=\"item selected\""+pug.attr("data-value", field.id, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = field.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var field = $$obj[pug_index1];
if (field.label) {
pug_html = pug_html + "\u003Cdiv" + (" class=\"item selected\""+pug.attr("data-value", field.id, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = field.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
    }
  }
}).call(this);

      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var task = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"header\"\u003E\u003Cimg" + (" class=\"ui avatar image\""+pug.attr("src", `../images/icons/${task.icon}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = task.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
// iterate task.fields
;(function(){
  var $$obj = task.fields;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var field = $$obj[pug_index2];
if (field.label) {
pug_html = pug_html + "\u003Cdiv" + (" class=\"item selected\""+pug.attr("data-value", field.id, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = field.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var field = $$obj[pug_index2];
if (field.label) {
pug_html = pug_html + "\u003Cdiv" + (" class=\"item selected\""+pug.attr("data-value", field.id, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = field.label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
    }
  }
}).call(this);

    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"extra content\"\u003E\u003Cdiv class=\"pull-right\"\u003E\u003Cdiv class=\"ui button green refresh-btn-js\"\u003E\u003Ci class=\"icon refresh\"\u003E\u003C\u002Fi\u003ERefresh columns\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"selectedColumns" in locals_for_with?locals_for_with.selectedColumns:typeof selectedColumns!=="undefined"?selectedColumns:undefined,"tasksWithFields" in locals_for_with?locals_for_with.tasksWithFields:typeof tasksWithFields!=="undefined"?tasksWithFields:undefined));;return pug_html;};
module.exports = template;