'use strict'

const RSVP        = require('es6-promise').Promise
const dayjs       = require('dayjs')

const api         = require('./../hub/components/api.es6')
const hubpubsub   = require('./../utils/hubpubsub.es6')
const views       = require('./../hub/views.es6')

export const initAsField = (groupId, selector)=> {
  const todoFields     = $(`${selector} .todos`)
  if (todoFields !== 0 && todoFields.length > 0) {
    apiFetchPeople(groupId).then( (people)=> {
      for (let f = 0; f < todoFields.length; f++) {
        const field = todoFields[f]
        renderTodoField(groupId, field, selector, people)
      }
      enableTodoEvents(groupId, selector, people)
    })
  }
}

const renderTodoField = (groupId, field, selector, people)=> {
  if ((localStorage.getItem('user-email') !== null) && (localStorage.getItem('auth-token') !== null)) {
    const addSelector = `${selector} .todos-add`
    enableTodoPopup(addSelector, groupId, field, selector, people)
    renderListOfTodos(groupId, field, selector, people)
  } else {
    $(field).find('.todos-blank .yellow-strip').html('Login to enable this field...')
    $(field).find('.todos-blank').show()
  }
}

const renderListOfTodos = (groupId, field, selector, people)=> {
  return new RSVP.Promise((resolve, reject)=> {
    let currentTodoIds = $(field).find('input').val()
    if (currentTodoIds !== '') {
      $(field).find('.todos-list').replaceWith(views.render('todos/list-of-todos-for-field', {
        loading: true
      }))

      initiateTemplateTodos(groupId, currentTodoIds).then( (newTodoIds)=> {
        if (newTodoIds !== undefined) {
          currentTodoIds = newTodoIds
          $(field).find('input').val(currentTodoIds)
        }
        apiFetchTodos(groupId, currentTodoIds).then( (todos)=> {
          const formattedTodos = todos.map( (todo)=> {
            if (todo.assignedTo !== undefined) {
              todo.assignedPeople = todo.assignedTo.map( (a)=> {
                return _.find(people, ( (p)=> {
                  return (p.id == a)
                }))
              })
            }
            if (todo.dueAt !== undefined) todo.dueAtDisplay = dayjs(todo.dueAt).format('ddd, D MMM YYYY')
            return todo
          })
          $(field).find('.todos-list').replaceWith(views.render('todos/list-of-todos-for-field', {
            todos:  formattedTodos,
            people: people
          }))
          todos.forEach( (t)=> {
            const updateSelector = `#todo-${t.id}.todo-item:not(.todo-disabled) span`
            enableTodoPopup(updateSelector, groupId, field, selector, people)
          })
          resolve()
        })
      })
    } else {
      $(field).find('.todos-list').replaceWith(views.render('todos/list-of-todos-for-field', { }))
      resolve()
    }
  })
}

export const initInHub = (groupId, selector)=> {
  const todoFields     = $(`${selector} .todo-item:not(.todo-disabled)`)
  apiFetchPeople(groupId).then( (people)=> {
    people.forEach((person)=> {
      $(`.todo-assignedToId-${person.id}`).text(`${person.firstname} ${person.lastname}`).removeClass('hidden')
    })
    const addSelector = `${selector} .todos-add`
    enableTodoPopup(addSelector, groupId, undefined, selector, people)  

    for (let f = 0; f < todoFields.length; f++) {
      const field = todoFields[f]
      const id = $(field).attr('id')
      const updateSelector = `#${id}.todo-item:not(.todo-disabled) span`
      enableTodoPopup(updateSelector, groupId, false, selector, people)
    }
    enableTodoEvents(groupId, selector, people)
  })
}

export const enableTodoEvents = (groupId, selector, people)=> {
  // toggle todo's status between incomplete(overdue/upcoming)/complete
  $(selector).on('click', '.todo-item:not(.todo-disabled) i.icon', (e)=> {
    const todoId = $(e.target).parent().attr('id')
    if ((todoId !== undefined) && (todoId !== '')) {
      $(e.target).removeClass().addClass('large blue notched circle loading icon')
      apiToggleTodo(groupId, todoId.substring(5)).then( (status)=> {
        let todoData = $(`#${todoId}`).data('todo')
        todoData.status = status
        $(`#${todoId}`).data('todo', todoData)

        let icon = 'large grey circle outline icon'
        let date = 'ui label tiny dueAtjs basic'

        if (status == 'complete') icon = 'large green check circle icon'
        else if (status == 'overdue') {
          icon = 'large orange circle outline icon'
          date = 'ui label tiny dueAtjs orange'
        } else if (status == 'expired') {
          icon = 'large red clock icon'
          date = 'ui label tiny dueAtjs red'
        }

        $(e.target).removeClass().addClass(icon)
        $(e.target).next('span').removeClass().addClass(`todos-status-${status}`)
        $(e.target).next('span').find('.dueAtjs').removeClass().addClass(date)
      })
    }
  })

  $(selector).on('click', '#todo-showmore-js', (e)=> {
    $(e.target).closest('#todo-showmore-js').addClass('hidden')
    $(e.target).closest('#todo-showmore-js').parent().find('#todo-showless-js').removeClass('hidden')
    $(e.target).closest('#todo-showmore-js').parent().find('.todo-hidden').removeClass('todo-hidden').addClass('todo-show')
  })

  $(selector).on('click', '#todo-showless-js', (e)=> {
    $(e.target).closest('#todo-showless-js').addClass('hidden')
    $(e.target).closest('#todo-showless-js').parent().find('#todo-showmore-js').removeClass('hidden')
    $(e.target).closest('#todo-showless-js').parent().find('.todo-show').removeClass('todo-show').addClass('todo-hidden')
  })
}

const enableTodoPopup = (popupSelector, groupId, field, selector, people)=> {
  const now = dayjs().valueOf()
  $(popupSelector).popup({
    popup:          '.todos-popup.popup',
    preserve:       true,
    movePopup:      true,
    exclusive:      true,
    inline:         true,
    variation:      'wide',
    position:       'bottom left',
    on:             'click',

    onVisible:      ()=> {
      let todo = {
        label:      '',
        assignedTo: [],
        dueAt:      '',
        overdueAction: 'enabled',
        inform:     'none',
        reschedule: 'Never'
      }
      const selectedTodo = $(popupSelector).parent().attr('id')
      if (selectedTodo !== undefined) {
        todo = $(`#${selectedTodo}`).data('todo')
        if (todo.dueAt !== undefined) todo.dueAt = dayjs(todo.dueAt).format('MMMM D, YYYY')
        todo.overdueAction = ((todo.expireAt !== undefined) && (todo.expireAt != '') && (todo.expireAt != '-')) ? 'disabled' : 'enabled'
      }

      $('.todos-popup.ui.popup').html(views.render('todos/todo-popup', {
        todo:       todo,
        peopleList: people,
        rescheduleList: ['Never', 'Weekly', 'Every two weeks', 'Monthly', 'Every two months', 'Every three months', 'Every four months', 'Every six months', 'Annually', 'Every two years', 'Every three years', 'Every four years', 'Every five years']
      }))

      $('#todo-assignedTo-dropdown-js').dropdown({})
      $('#todo-reschedule-dropdown-js').dropdown({})
      if (todo.dueAt === undefined) {
        $('#todo-overdue-js').hide()
        $('#todo-reschedule-js').hide()
      }

      $(`.todos-popup.ui.popup #datepicker.ui.calendar`).calendar({
        type: 'date',
        onChange: (date, text, mode)=> {
          if (text == '') {
            $('#todo-reschedule-dropdown-js.ui.dropdown').dropdown('restore defaults')
            $('#todo-overdue-js').hide()
            $('#todo-reschedule-js').hide()
          } else {
            $('#todo-overdue-js').show()
            $('#todo-reschedule-js').show()
          }
        }
      })

      $('body').on('click', '.todos-popup .todo-save-btn-js', (e)=> {
        e.preventDefault()
        $('.todos-popup .todo-save-btn-js').addClass('loading')

        const id = $('.todos-popup [name="id"]').val()
        const assignedTo = $('.todos-popup [name="assignedTo"]').val().split(',').filter( (a)=> {
          return (a != '') && (!isNaN(a))
        }).map( (a)=> { return parseInt(a) })
        const dueAt = $('.todos-popup [name="dueAt"]').val()
        const overdueAction = $('.todos-popup [name="overdueAction"]:checked').val()

        const todo = {
          groupId:      parseInt(groupId),
          label:        $('.todos-popup [name="label"]').val(),
          inform:       $('.todos-popup [name="inform"]:checked').val(),
          status:       $('.todos-popup [name="status"]').val(),
          reschedule:   $('.todos-popup [name="reschedule"]').val()
        }
        if (id != '') todo.id = parseInt(id)
        if (assignedTo != '') todo.assignedTo = assignedTo
        
        if (dueAt != '') {
          todo.dueAt = dayjs(dueAt, 'MMMM D, YYYY').add(23, 'hour').add(59, 'minute').valueOf()
          if (overdueAction == 'disabled') todo.expireAt = todo.dueAt
        }

        apiPostTodos(groupId, [todo]).then( (updatedTodos)=> {
          $(popupSelector).popup('hide')

          if (updatedTodos.length > 0) {
            $(`#${selectedTodo}`)
            if (todo.assignedTo !== undefined) updatedTodos[0].assignedTo = todo.assignedTo
            $(`#${selectedTodo}`).data('todo', updatedTodos[0])
          }

          if (field) {
            const fieldValue = $(field).find('input').val()
            const currentTodoIds = (fieldValue == '') ? [] : fieldValue.split(',')
            updatedTodos.forEach( (todo)=> {
              if (currentTodoIds.indexOf(todo.id.toString()) === -1) currentTodoIds.push(todo.id.toString())
            })
            $(field).find('input').val(currentTodoIds.join(','))

            renderListOfTodos(groupId, field, selector, people).then( ()=> {})
          }
        })
      })

      $('body').on('click', '.todos-popup .close-popup', (e)=> {
        $(popupSelector).popup('hide')
      })
    },

    onHide:       ()=> {
      $('body').off('click', '.todos-popup .todo-save-btn-js')
      $('body').off('click', '.todos-popup .close-popup')
      $('.todos-popup.ui.popup').html(views.render('components/empty-popup', {}))
    }
  })
}

export const apiFetchPeople = (groupId)=> {
  return new RSVP.Promise((resolve, reject)=> {
    let url = `/api/user/group/${groupId}/users/all`
    api.send(url).then ( (data)=> {
      resolve(data.users)
    })
  })
}

const initiateTemplateTodos = (groupId, todoIdsStr)=> {
  return new RSVP.Promise((resolve, reject)=> {
      const ids = todoIdsStr.split(',')
      if (isNaN(ids[0])) {
        const newIds = todoIdsStr.split('.')
        const newTodos = []
        for (let t = 0; t < newIds.length; t++) {
          if (newIds[t] !== '') {
            newTodos.push({
              groupId:      parseInt(groupId),
              label:        newIds[t],
              inform:       'none',
              status:       'incomplete'
            })
          }
        }
        if (newTodos.length > 0) {
          apiPostTodos(groupId, newTodos).then( (updatedTodos)=> {
            const newTodoIds = updatedTodos.map( (todo)=> {
              return todo.id
            })
            hubpubsub.publish('notification.show', { kind: 'green', message: 'Predefined todo\'s have been created automatically.<br/>Please save this form so that they are not recreated in future.'})
            resolve(newTodoIds.join(','))
          })
        } else {
          resolve()
        }
      } else {
        resolve()
      }
  })
}

const apiFetchTodos = (groupId, todoIds)=> {
  return new RSVP.Promise((resolve, reject)=> {
    let url = `/api/group/${groupId}/todos?ids=${todoIds}`
    api.send(url).then ( (data)=> {
      resolve(data.todos)
    })
  })
}

const apiPostTodos = (groupId, todos)=> {
  return new RSVP.Promise((resolve, reject)=> {
    let url = `/api/group/${groupId}/todos`
    api.send(url, 'POST', todos).then ( (data)=> {
      resolve(data)
    })
  })
}

const apiToggleTodo = (groupId, todoId)=> {
  return new RSVP.Promise((resolve, reject)=> {
    let url = `/api/group/${groupId}/todos/toggle/${todoId}`
    api.send(url, 'GET', undefined, {'dataType': 'text'}).then ( (data)=> {
      resolve(data)
    })
  })
}
