var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (count) {pug_html = pug_html + "\u003Ci class=\"close close-popup icon\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"header\"\u003ESure you want to delete?\u003C\u002Fdiv\u003E\u003Cdiv class=\"description\"\u003E";
if ((count == 1)) {
pug_html = pug_html + "\u003Cp\u003EDeleting the selected person is permanent and there is no undo...\u003C\u002Fp\u003E\u003Cp\u003EThe person's profile and history will be deleted completely.\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp\u003EDeleting the \u003Cstrong\u003E" + (pug.escape(null == (pug_interp = count) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E selected people is permanent and there is no undo...\u003C\u002Fp\u003E\u003Cp\u003EEach person's profile and history will be deleted completely.\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"extra content\"\u003E\u003Cdiv class=\"pull-right\"\u003E\u003Cdiv class=\"ui button red delete-btn-js\"\u003E\u003Ci class=\"trash alternate outline icon\"\u003E\u003C\u002Fi\u003EDelete permanently\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"count" in locals_for_with?locals_for_with.count:typeof count!=="undefined"?count:undefined));;return pug_html;};
module.exports = template;