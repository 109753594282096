var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (branch, depth, groupId) {var d = 0
var indent = ''
var levels = {'company': 'Maker account', 'application': 'App', 'organization': 'Organization/team'}
while (d < depth) {
indent += '&nbsp;&nbsp;&nbsp;&nbsp;'
d++
}
pug_html = pug_html + "\u003Ctr\u003E";
if (branch.id == groupId) {
pug_html = pug_html + "\u003Ctd class=\"no-wrap\"\u003E" + (null == (pug_interp = indent) ? "" : pug_interp) + "\u003Cimg" + (" class=\"rounded\""+pug.attr("src", require(`../../images/people-groups-${branch.level}.png`), true, true)+" width=\"25\" height=\"25\"") + "\u003E&nbsp;&nbsp;\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = branch.label) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E\u003C\u002Ftd\u003E\u003Ctd class=\"right aligned\"\u003E\u003Cdiv class=\"ui green label\"\u003E\u003Ci class=\"checkmark icon\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = `Active ${levels[branch.level]}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Ftd\u003E";
}
else {
pug_html = pug_html + "\u003Ctd colspan=\"2\"\u003E" + (null == (pug_interp = indent) ? "" : pug_interp) + "\u003Ca" + (pug.attr("href", `/hub/switch/${branch.id}`, true, true)) + "\u003E\u003Cimg" + (" class=\"rounded\""+pug.attr("src", require(`../../images/people-groups-${branch.level}.png`), true, true)+" width=\"25\" height=\"25\"") + "\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = branch.label) ? "" : pug_interp)) + "\u003Cspan class=\"yellow-strip\"\u003E" + (pug.escape(null == (pug_interp = levels[branch.level]) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003C\u002Ftd\u003E";
}
pug_html = pug_html + "\u003C\u002Ftr\u003E";}.call(this,"branch" in locals_for_with?locals_for_with.branch:typeof branch!=="undefined"?branch:undefined,"depth" in locals_for_with?locals_for_with.depth:typeof depth!=="undefined"?depth:undefined,"groupId" in locals_for_with?locals_for_with.groupId:typeof groupId!=="undefined"?groupId:undefined));;return pug_html;};
module.exports = template;