var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (Object, columns, csvHeader, fields, referrerUri, samples) {pug_html = pug_html + "\u003Cdiv class=\"row\" id=\"importer-step-02\"\u003E\u003Cdiv class=\"three wide column\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"ten wide column\"\u003E\u003Cdiv class=\"ui raised fluid card\"\u003E\u003Cdiv class=\"content\"\u003E\u003Ch3\u003EMap .csv columns to fields\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"content\"\u003E\u003Ctable class=\"ui single line fixed table\"\u003E\u003Cthead\u003E\u003Ctr\u003E\u003Cth class=\"right aligned\"\u003EColumn in .csv file\u003C\u002Fth\u003E\u003Cth\u003E\u003C\u002Fth\u003E\u003Cth\u003EField label in system\u003C\u002Fth\u003E\u003C\u002Ftr\u003E\u003C\u002Fthead\u003E\u003Ctbody\u003E";
// iterate columns
;(function(){
  var $$obj = columns;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var column = $$obj[pug_index0];
if ((column != '')) {
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd class=\"top right aligned\"\u003E";
if (csvHeader) {
pug_html = pug_html + "\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = column) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E\u003Cbr\u003E";
}
// iterate samples
;(function(){
  var $$obj = samples;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var sample = $$obj[pug_index1];
pug_html = pug_html + (pug.escape(null == (pug_interp = sample[column]) ? "" : pug_interp)) + "\u003Cbr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var sample = $$obj[pug_index1];
pug_html = pug_html + (pug.escape(null == (pug_interp = sample[column]) ? "" : pug_interp)) + "\u003Cbr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd class=\"center aligned\"\u003E\u003Ci class=\"big circle arrow outline right alternate grey icon\"\u003E\u003C\u002Fi\u003E\u003C\u002Ftd\u003E\u003Ctd style=\"overflow: visible;\"\u003E\u003Cdiv class=\"ui fluid search selection dropdown\"\u003E";
var ix = Object.values(fields).indexOf(column)
var selectedValue = (ix > -1) ? Object.keys(fields)[ix] : ''
pug_html = pug_html + "\u003Cinput" + (" class=\"mappedfields\""+pug.attr("name", `${column}`, true, true)+" type=\"hidden\""+pug.attr("value", `${selectedValue}`, true, true)) + "\u003E\u003Ci class=\"dropdown icon\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"default text\"\u003EChoose a field\u003C\u002Fdiv\u003E\u003Cdiv class=\"menu\"\u003E\u003Cdiv class=\"item\" data-value=\"\"\u003E\u003Cem\u003ENone (ignore this column)\u003C\u002Fem\u003E\u003C\u002Fdiv\u003E";
// iterate fields
;(function(){
  var $$obj = fields;
  if ('number' == typeof $$obj.length) {
      for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
        var value = $$obj[key];
pug_html = pug_html + "\u003Cdiv" + (" class=\"item\""+pug.attr("data-value", key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = value) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;
      var value = $$obj[key];
pug_html = pug_html + "\u003Cdiv" + (" class=\"item\""+pug.attr("data-value", key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = value) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var column = $$obj[pug_index0];
if ((column != '')) {
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd class=\"top right aligned\"\u003E";
if (csvHeader) {
pug_html = pug_html + "\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = column) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E\u003Cbr\u003E";
}
// iterate samples
;(function(){
  var $$obj = samples;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var sample = $$obj[pug_index3];
pug_html = pug_html + (pug.escape(null == (pug_interp = sample[column]) ? "" : pug_interp)) + "\u003Cbr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var sample = $$obj[pug_index3];
pug_html = pug_html + (pug.escape(null == (pug_interp = sample[column]) ? "" : pug_interp)) + "\u003Cbr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd class=\"center aligned\"\u003E\u003Ci class=\"big circle arrow outline right alternate grey icon\"\u003E\u003C\u002Fi\u003E\u003C\u002Ftd\u003E\u003Ctd style=\"overflow: visible;\"\u003E\u003Cdiv class=\"ui fluid search selection dropdown\"\u003E";
var ix = Object.values(fields).indexOf(column)
var selectedValue = (ix > -1) ? Object.keys(fields)[ix] : ''
pug_html = pug_html + "\u003Cinput" + (" class=\"mappedfields\""+pug.attr("name", `${column}`, true, true)+" type=\"hidden\""+pug.attr("value", `${selectedValue}`, true, true)) + "\u003E\u003Ci class=\"dropdown icon\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"default text\"\u003EChoose a field\u003C\u002Fdiv\u003E\u003Cdiv class=\"menu\"\u003E\u003Cdiv class=\"item\" data-value=\"\"\u003E\u003Cem\u003ENone (ignore this column)\u003C\u002Fem\u003E\u003C\u002Fdiv\u003E";
// iterate fields
;(function(){
  var $$obj = fields;
  if ('number' == typeof $$obj.length) {
      for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
        var value = $$obj[key];
pug_html = pug_html + "\u003Cdiv" + (" class=\"item\""+pug.attr("data-value", key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = value) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;
      var value = $$obj[key];
pug_html = pug_html + "\u003Cdiv" + (" class=\"item\""+pug.attr("data-value", key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = value) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"content\"\u003E\u003Cdiv class=\"right floated\"\u003E\u003Ca" + (" class=\"ui right basic button\""+pug.attr("href", `${referrerUri}`, true, true)) + "\u003ECancel\u003C\u002Fa\u003E\u003Cbutton class=\"ui right labeled icon green button\" id=\"importer-next-step-02-js\"\u003E\u003Ci class=\"right arrow icon\"\u003E\u003C\u002Fi\u003ENext step\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"three wide column\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"Object" in locals_for_with?locals_for_with.Object:typeof Object!=="undefined"?Object:undefined,"columns" in locals_for_with?locals_for_with.columns:typeof columns!=="undefined"?columns:undefined,"csvHeader" in locals_for_with?locals_for_with.csvHeader:typeof csvHeader!=="undefined"?csvHeader:undefined,"fields" in locals_for_with?locals_for_with.fields:typeof fields!=="undefined"?fields:undefined,"referrerUri" in locals_for_with?locals_for_with.referrerUri:typeof referrerUri!=="undefined"?referrerUri:undefined,"samples" in locals_for_with?locals_for_with.samples:typeof samples!=="undefined"?samples:undefined));;return pug_html;};
module.exports = template;