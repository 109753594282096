var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (cardData) {pug_html = pug_html + "\u003Cdiv class=\"ui segment green\"\u003E\u003Cdiv class=\"pull-left\" style=\"margin-right: 0.7em;\"\u003E\u003Ci" + (pug.attr("class", pug.classes(["middle","aligned","icon",cardData.icon], [false,false,false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"pull-left\" style=\"max-width: 90%;\"\u003E\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = cardData.header) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E\u003Cdiv class=\"description\"\u003E";
// iterate cardData
;(function(){
  var $$obj = cardData;
  if ('number' == typeof $$obj.length) {
      for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
        var value = $$obj[key];
if (((key != 'icon') && (key != 'header') && (key != 'link'))) {
pug_html = pug_html + "\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = `${key}: `) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E" + (pug.escape(null == (pug_interp = value) ? "" : pug_interp)) + "\u003Cbr\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;
      var value = $$obj[key];
if (((key != 'icon') && (key != 'header') && (key != 'link'))) {
pug_html = pug_html + "\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = `${key}: `) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E" + (pug.escape(null == (pug_interp = value) ? "" : pug_interp)) + "\u003Cbr\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"clearfix\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"cardData" in locals_for_with?locals_for_with.cardData:typeof cardData!=="undefined"?cardData:undefined));;return pug_html;};
module.exports = template;